import config from "../../config/shared";
import Loading from "../include/loading/loading";
const Home = () => {
  return (
    <>
      <div className="flex flex-col items-center justify-center min-h-screen">
        <div className="glass_card_stone border border-dashed border-rose-500 transition-transform duration-500 hover:scale-10 p-8 max-w-sm mx-auto shadow-lg">
          <Loading center={false} mt={5}/>
          <h1 className="text-2xl font-semibold text-gray-100 mb-2 text-center mt-10">
            در حال بروزرسانی هستیم ...
          </h1>
          <p className="text-gray-300 text-center">
            به زودی در دسترس قرار خواهد گرفت
          </p>
          <p className="text-gray-300 text-center">
            برای دریافت اخبار های بیشتر حتما مارو در پلتفرم های زیر دنبال کنید
            💛
          </p>
        </div>

        <div className="flex my-7">
          {Object.entries(config.platform).map(([key, value]) => {
            return (
              <a
                href={value}
                className="w-16 h-16 glass_card_rose flex justify-center items-center mx-5"
              >
                <img src={`${process.env.PUBLIC_URL}/gif/${key}.gif`} alt="" />
              </a>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Home;
