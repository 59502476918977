import { useEffect, useRef, useState } from "react";
import axios from "../../../config/axios";
import { useNotification } from "../../include/notif/hook";
import Loading from "../../include/loading/loading";
import ERR from "../../include/err/message";
const UserLogs = () => {
  const [logs, setLogs] = useState(null);
  const countInput = useRef();
  const [err, setErr] = useState(null);
  const [showNotification, NotificationComponent] = useNotification();

  useEffect(() => {
    async function load() {
      axios
        .get("/admin/user-logs")
        .then(async (res) => {
          if (res && res.data.message === "ok") {
            setLogs(res.data.data);
          } else {
            setErr(res.data.message);
          }
        })
        .catch((err) => {
          if (err.response) {
            setErr(err.response.data.message);
          } else {
            setErr(err.message);
          }
        });
    }
    load();
  }, []);

  const typeHandle = (type) => {
    if (type === "info") {
      return `text-stone-600`;
    } else if (type === "danger") {
      return `text-red-600`;
    } else if (type === "warn") {
      return `text-yellow-400`;
    } else if (type === "success") {
      return `text-green-600`;
    } else {
      return `text-stone-600`;
    }
  };
  const borderHandel = (type) => {
    if (type === "info") {
      return `border-stone-600`;
    } else if (type === "danger") {
      return `border-red-600`;
    } else if (type === "warn") {
      return `border-yellow-400`;
    } else if (type === "success") {
      return `border-green-600`;
    } else {
      return `border-stone-600`;
    }
  };

  if (err) {
    return <ERR msg={err} />;
  }

  const CopyHandle = (id) => {
    navigator.clipboard.writeText(id);
    showNotification("ایدی ادمین مورد نظر کپی شد", "موفق", 5, "ok");
  };

  return (
    <div className="flex-1 mt-5 w-full flex flex-col justify-center items-center transition-all duration-300">
      {NotificationComponent}

      {!logs ? (
        <Loading center={true} />
      ) : (
        <>
          {logs.length === 0 ? (
            <ERR msg={"لاگی ثبت نشده"} />
          ) : (
            logs.reverse().map((m, i) => (
              <div
                key={i}
                className={`w-full xl:w-9/12 text-sm xl:text-base mt-3 flex flex-col p-4 glass_card_light border ${borderHandel(
                  m.type
                )}`}
              >
                <div className="flex justify-between flex-col items-center md:flex-row">
                  <h1
                    className={`${typeHandle(m.type)} font-bold animate-pulse`}
                  >
                    {m.title}
                  </h1>
                  <p className="my-5 text-center md:my-0">{m.message}</p>
                  <p
                    onClick={() => CopyHandle(m._id)}
                    className="cursor-pointer hover:text-blue-500 mt-2  md:mt-0"
                  >
                    {m.userId.email.split("@", 1)}
                  </p>
                  <p className="text-[12px] mt-2 md:mt-0">{m.createdAt}</p>
                </div>
              </div>
            ))
          )}
        </>
      )}
    </div>
  );
};

export default UserLogs;
