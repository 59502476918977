import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FcPrevious, FcDownLeft } from "react-icons/fc";

const Side = ({ Icons, name, id, subLinks }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="w-full flex flex-col mt-3 p-1">
      <div 
        className="flex p-2 justify-between items-center w-full border border-slate-900 rounded-lg cursor-pointer hover:bg-blue-950 hover:bg-opacity-50 hover:border-blue-500"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center w-full">
          <div className="ml-2">
            <Icons />
          </div>
          <p className="text-sm">{name}</p>
        </div>
        <FcPrevious className={`transform transition-transform ${isOpen ? 'rotate-[270deg]' : ''}`} />
      </div>

      {isOpen && (
        <div className="flex w-full flex-col mt-1 pl-4">
          {subLinks.map((subLink, index) => (
            <NavLink
              key={index}
              to={`/admin/${id}/${subLink.id}`}
              className={({ isActive }) =>
                `flex my-1 py-1 pr-5 w-full rounded-xl items-center ${
                  isActive ? 'text-blue-500' : 'text-gray-400 hover:text-white'
                }`
              }
            >
              <FcDownLeft />
              <p className="text-sm mr-1">{subLink.name}</p>
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
};

export default Side;
