import App from './app'
import reactdom from 'react-dom'
import './index.css';

reactdom.render(

    <App />,
    document.getElementById('root')
)



