import React, { useEffect, useState } from "react";
import axios from "../../../config/axios";
import { format } from "date-fns";
import { useNotification } from "../../include/notif/hook";
import {
  CiStreamOn,
  CiStreamOff,
  CiPlay1,
  CiEdit,
  CiWarning,
  CiPause1,
  CiTrash,
  CiTimer,
} from "react-icons/ci";
import ERR from "../../include/err/message";
import Loading from "../../include/loading/loading";
import { useConfirm } from "../../include/confrim/hook";

const LicenseList = () => {
  const [licenses, setLicenses] = useState([]);
  const [err, setErr] = useState(null);
  const [showNotification, NotificationComponent] = useNotification();
  const [showConfirm, confirmComponent] = useConfirm();

  useEffect(() => {
    const loadLicense = async () => {
      axios
        .get("/admin/license-list")
        .then((res) => {
          if (res && res.data.message === "ok") {
            setLicenses(res.data.data);
          } else {
            setErr(res.data.message);
          }
        })
        .catch((err) => {
          if (err.response) {
            setErr(err.response.data.message);
          } else {
            setErr(err.message);
          }
        });
    };

    loadLicense();
  }, []);

  const licenseFormatHandel = (i) => {
    let start = i.slice(0, 3);
    let end = i.slice(i.length - 3);
    return `${start} *** ${end}`;
  };

  const timeFormat = (date, isLifeTime) => {
    if (isLifeTime) {
      return "∞";
    }

    return format(new Date(date), "yyyy-MM-dd");
  };
  const IPFormat = (ip, isIpLock) => {
    if (!isIpLock) {
      return "🔓";
    }
    return ip;
  };

  const copy = (id) => {
    navigator.clipboard.writeText(id);
    showNotification("لایسنس مورد نظر کپی شد", "موفق", 5, "ok");
  };

  const delHandel = async (id) => {
    showConfirm("آیا از حذف این لایسنس مطمعن هستید ؟", "حذف لایسنس", () => {
      axios
        .delete("/admin/delete-license", {
          data: {
            id: id,
          },
        })
        .then((r) => {
          if (r && r.data.message === "ok") {
            let alllicense = [...licenses];
            let updateLicense = alllicense.filter((i) => i._id !== id);
            setLicenses(updateLicense);
            showNotification("لاینس مورد نظر حذف شد", "موفق", 5, "ok");
          } else {
            showNotification(r.data.message, "خطا", 5, "err");
          }
        })
        .catch((err) => {
          if (err.response) {
            showNotification(err.response.data.message, "خطا", 5, "err");
          } else {
            showNotification(err.message, "خطا", 5, "err");
          }
        });
    });
  };

  const edithandel = (id) => {};

  const controlhandel = async (id) => {
    let res = await axios.put("admin/change-license", {
      id: id,
    });

    if (res.data.message === "ok") {
      setLicenses(res.data.data);
    } else {
      alert(res.data.message);
    }
  };

  if (err) {
    return <ERR msg={err} />;
  }

  if (licenses.length == 0) {
    return <Loading center={true} />;
  }

  return (
    <div className="flex-1 mt-5">
      {NotificationComponent}
      {confirmComponent}
      <div>
        <h1 className="font-semibold mb-4 text-lg md:text-2xl text-center">
          لیست لایسنس ها :
        </h1>
      </div>

      <div className="shadow-lg backdrop-blur-3xl bg-stone-900/30 shadow-gray-950 w-full border-dotted border-2 border-rose-700 rounded-b-xl">
        <div className="overflow-auto h-[85vh]">
          <table className=" w-full text-center text-[12px] sm:text-sm ">
            <thead>
              <tr className="bg-rose-800 rounded-2xl text-white">
                <th className="p-3"></th>
                <th className="p-3">نام مشتری</th>
                <th className="p-3">نام سازنده</th>
                <th className="p-3">ایدی محصول</th>
                <th className="p-3">لایسنس</th>
                <th className="p-3">آی پی</th>
                <th className="p-3">فعالسازی</th>
                <th className="p-3">منقضی</th>
                <th className="p-3">تنظیمات</th>
              </tr>
            </thead>
            <tbody>
              {licenses.map((l, i) => (
                <tr key={i} className="p-3 text-gray-300">
                  <td className="pr-3">
                    {!l.isLifeTime && new Date() >= new Date(l.expired) ? (
                      <CiTimer className="cursor-pointer  animate-ping ml-2 text-red-500" />
                    ) : (
                      <>
                        {l.active ? (
                          <CiStreamOn className="cursor-pointer  animate-ping ml-2 text-green-500" />
                        ) : (
                          <CiStreamOff className="cursor-pointer  animate-ping ml-2 text-orange-500" />
                        )}
                      </>
                    )}
                  </td>
                  <td className="p-2">{l.userName}</td>
                  <td className="p-2">{l.adminName}</td>
                  <td className="p-2">{l.product.tag}</td>
                  <td
                    className="p-2 cursor-pointer hover:text-rose-700 transition-all"
                    onClick={() => copy(l.license)}
                  >
                    {licenseFormatHandel(l.license)}
                  </td>
                  <td className="p-2">{IPFormat(l.server, l.isIpLock)}</td>
                  <td className="p-2">{timeFormat(l.createdAt, null)} </td>
                  <td className="p-2">{timeFormat(l.expired, l.isLifeTime)}</td>
                  <td className="flex justify-center items-center text-center mt-2">
                    <CiEdit
                      className="text-lg text-blue-500 cursor-pointer"
                      onClick={() => edithandel(l._id)}
                    />
                    <CiTrash
                      className="text-lg text-red-500 cursor-pointer mx-2"
                      onClick={() => delHandel(l._id)}
                    />

                    {!l.isLifeTime && new Date() >= new Date(l.expired) ? (
                      <CiWarning className="text-lg text-yellow-500 cursor-not-allowed" />
                    ) : (
                      <>
                        {l.active ? (
                          <CiPause1
                            className="text-lg text-orange-500 cursor-pointer"
                            onClick={() => controlhandel(l._id)}
                          />
                        ) : (
                          <CiPlay1
                            className="text-lg text-green-500 cursor-pointer"
                            onClick={() => controlhandel(l._id)}
                          />
                        )}
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default LicenseList;
