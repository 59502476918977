const Toman = ({ price }) => {
  function formatPrice(price) {
    let priceStr = price.toString();
    return priceStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return <>{formatPrice(price)} تومان</>;
};

export default Toman;
