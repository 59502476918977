import { useState } from "react";
import Confirm from "./confirm";

export const useConfirm = () => {
  const [confirm, setConfirm] = useState({
    show: false,
    text: "",
    title: "",
    onConfirm: () => {},
  });

  const showConfirm = (text, title, onConfirm) => {
    setConfirm({ onConfirm, title, text, show: true });
  };

  const confirmComponent = confirm.show ? (
    <Confirm {...confirm} setShow={(show) => setConfirm((prev) => ({ ...prev, show }))} />
  ) : null;

  return [showConfirm, confirmComponent];
};
