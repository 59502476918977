import { BsFillTrashFill } from "react-icons/bs";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import { useEffect, useState } from "react";
import axios from "../../../config/axios";
import { useNotification } from "../../include/notif/hook";
import Loading from "../../include/loading/loading";
import ERR from "../../include/err/message";
import config from "../../../config/shared";
import Toman from "../../include/textForamt/toman";
import levelForamter from "../../include/textForamt/level";
const Info = () => {
  const [admin, setAdmin] = useState(null);
  const [err, setErr] = useState(null);
  const [showNotification, NotificationComponent] = useNotification();
  useEffect(() => {
    async function load() {
      axios
        .get("/admin/dashboard")
        .then((res) => {
          if (res && res.data.message === "ok") {
            setAdmin(res.data.data);
          } else {
            setErr(res.data.message);
          }
        })
        .catch((err) => {
          if (err.response) {
            setErr(err.response.data.message);
          } else {
            setErr(err.message);
          }
        });
    }

    load();
  }, []);

  const deleteHandler = async (type, id) => {
    const endpoint = type === "gift" ? "/admin/del-gift" : "/admin/del-notif";
    axios
      .post(endpoint, { id: id })
      .then(async (res) => {
        if (res && res.data.message === "ok") {
          showNotification("با موفقیت پاک شد", "موفق", 5, "ok");
          setAdmin(res.data.data);
        } else {
          showNotification(res.data.message, "خطا", 5, "err");
        }
      })
      .catch((err) => {
        const message = err.response ? err.response.data.message : err.message;
        showNotification(message, "خطا", 5, "err");
      });
  };

  const textFormater = (num) => {
    const maxWarn = config.maxWarn || 100;
    const numbers = Math.floor(100 / maxWarn);

    if (num <= 0) {
      return (
        <p className="text-gray-400">
          وضعیت شما <span className="text-green-500">خوب</span> است
        </p>
      );
    } else if (num <= numbers) {
      return (
        <p className="text-gray-400">
          وضعیت شما <span className="text-orange-500">خوب نیست</span>
        </p>
      );
    } else if (num <= numbers * 2) {
      return (
        <p className="text-gray-400">
          وضعیت شما <span className="text-red-500">بد</span> است
        </p>
      );
    } else {
      return (
        <p className="text-gray-400">
          وضعیت شما <span className="text-red-500">خیلی بد</span> است
        </p>
      );
    }
  };

  if (err) {
    return <ERR msg={err} />;
  }

  if (!admin || !admin.userId) {
    return <Loading center={true} />;
  }

  return (
    <div className="flex-1 mt-5 w-full flex flex-col justify-center items-center transition-all duration-300">
      {NotificationComponent}
      <div className="relative w-full xl:w-9/12 h-[100px] sm:h-[300px] shadow-lg ">
        <div className="absolute top-0 left-0 w-full h-full ">
          <img
            src={`${process.env.PUBLIC_URL}${admin.userId.banner}`}
            alt="Background"
            className="object-cover w-full h-full rounded-2xl"
          />
        </div>
        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-black/25 to-rose-950/90 rounded-2xl"></div>
        <div className="relative flex items-center justify-between px-2 py-6 top-0 sm:top-36">
          <div className="flex items-center">
            <div
              className={`w-14 h-14 sm:w-28 sm:h-28 rounded-full overflow-hidden border-4 border-${
                admin.active ? "green" : "red"
              }-500 animate-pulse`}
            >
              <img
                src={`${process.env.PUBLIC_URL}${admin.userId.profile}`}
                alt="progfile"
                className="object-cover w-full h-full"
              />
            </div>

            <div className="mr-2 sm:mr-4 text-white">
              <h1 className="text-sm font-bold sm:text-xl">
                {admin.firstname}
              </h1>
              <p className="text-[10px] sm:text-sm text-rose-600">
                {admin.role.admin}
              </p>
              <p className="text-[10px] sm:text-sm text-rose-600">
                {admin.role.work}
              </p>

              {/* <p className="text-[10px] sm:text-sm text-rose-600">تاریخ عضویت :  1 1 1 </p> */}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full xl:w-9/12 flex justify-between flex-wrap mt-5">
        <div className="m-1 flex-1 md:m-2 flex flex-col justify-around items-center glass_card_rose border border-rose-600  h-48 min-w-32   md:h-64">
          <img
            className=""
            src={`${process.env.PUBLIC_URL}/img/logo/Keyboard.png`}
            alt=""
          />
          <div className="flex flex-col justify-around items-center">
            <p className="text-rose-600">
              {<Toman price={admin.balance.cash} />}
            </p>
            <p className="text-stone-500 text-sm mt-2">موجودی حساب</p>
          </div>
        </div>
        <div className="m-1 flex-1 md:m-2 flex flex-col justify-around items-center glass_card_rose border border-rose-600  h-48 min-w-32   md:h-64">
          <img
            className=""
            src={`${process.env.PUBLIC_URL}/img/logo/Verified.png`}
            alt=""
          />
          <div className="flex flex-col justify-around items-center">
            <p className="text-rose-600">
              {console.log(levelForamter(admin.balance.AC).level)}
              {levelForamter(admin.balance.AC).level}
            </p>
            <p className="text-stone-500 text-sm mt-2">سطح شما</p>
          </div>
        </div>

        <div className="m-1 flex-1 md:m-2 flex flex-col justify-around items-center glass_card_rose border border-rose-600  h-48 min-w-32   md:h-64">
          <img
            className=""
            src={`${process.env.PUBLIC_URL}/img/logo/coin.png`}
            alt=""
          />
          <div className="flex flex-col justify-around items-center">
            <p className="text-rose-600">{admin.userId.balance.PNG_Coin}</p>
            <p className="text-stone-500 text-sm mt-2"> پی ان جی کوین</p>
          </div>
        </div>

        <div className="m-1 flex-1 md:m-2 flex flex-col justify-around items-center glass_card_rose border border-rose-600  h-48 min-w-32  md:h-64">
          <img src={`${process.env.PUBLIC_URL}/img/logo/Chart.png`} alt="" />
          <div className="flex flex-col justify-around items-center">
            <p className="text-rose-600 text-sm md:text-base">
              {admin.sell.count}
            </p>
            <p className="text-stone-500 text-sm mt-1 md:mt-2">تعداد کل فروش</p>
          </div>
        </div>

        <div className="m-1 flex-1 md:m-2 flex flex-col justify-around items-center glass_card_rose border border-rose-600  h-48 min-w-32   md:h-64">
          <img
            className=""
            src={`${process.env.PUBLIC_URL}/img/logo/amar.png`}
            alt=""
          />
          <div className="flex flex-col justify-around items-center">
            <p className="text-rose-600">
              {<Toman price={admin.sell.price} />}
            </p>
            <p className="text-stone-500 text-sm mt-2">امار کل فروش</p>
          </div>
        </div>
      </div>
      <div className="mt-10 w-full xl:w-9/12  flex-col md:flex-row flex justify-around flex-grow">
        <div className="glass_card_stone flex flex-col  h-72  flex-1 rounded-xl  ">
          <div className="bg-rose-800 w-full p-2 rounded-t-xl text-center ">
            <p>باکس اعلان ها</p>
          </div>
          <div className="p-4 my-2 overflow-x-auto divide-current">
            {admin.notif.length === 0 ? (
              <p className="w-full text-center">پیغامی وجود ندارد</p>
            ) : null}
            {admin.notif
              .slice()
              .reverse()
              .map((n) => (
                <>
                  <div
                    key={n._id}
                    className="w-full flex justify-between divide-current mb-5"
                  >
                    <p className="text-sm">{n.message}</p>
                    <BsFillTrashFill
                      className="w-7 h-7 text-red-500"
                      onClick={() => deleteHandler("notif", n._id)}
                    />
                  </div>
                </>
              ))}
          </div>
        </div>
        <div className="glass_card_stone flex flex-col  h-72  flex-1 mt-5 md:mt-0 mx-0 md:mr-4 rounded-xl  ">
          <div className="bg-rose-800 w-full p-2 rounded-t-xl  text-center ">
            <p>باکس هدایا</p>
          </div>
          {admin.gift.length === 0 ? (
            <p className="w-full text-center mt-5">پیغامی وجود ندارد</p>
          ) : null}
          <div className="p-4 my-2 overflow-x-auto  divide-current">
            {admin.gift
              .slice()
              .reverse()
              .map((n) => (
                <>
                  <div
                    key={n._id}
                    className="w-full flex justify-between divide-current mb-5"
                  >
                    <p className="text-sm">{n.message}</p>
                    <BsFillTrashFill
                      className="w-7 h-7 text-red-500"
                      onClick={() => deleteHandler("gift", n._id)}
                    />
                  </div>
                </>
              ))}
          </div>
        </div>
      </div>

      <div className="mt-10 w-full xl:w-9/12 flex-col   sm:flex-row flex justify-between flex-grow mb-10">
        <div className=" py-1 px-2 text-white flex-col justify-between glass_card_stone ">
          <div className="flex justify-center ">
            <Gauge
              width={200}
              height={200}
              value={admin.warn.length + 0.1}
              startAngle={-110}
              endAngle={110}
              valueMax={config.maxWarn}
              sx={{
                [`& .${gaugeClasses.valueText}`]: {
                  fontSize: 20,
                  transform: "translate(0px, 0px)",
                },
                [`& .${gaugeClasses.valueArc}`]: {
                  fill: "rgb(219, 24, 57)",
                },
                [`& .${gaugeClasses.referenceArc}`]: {
                  fill: "gray",
                },
              }}
              text={({ value, valueMax }) => ``}
            />
          </div>

          <div className="w-full text-center my-3">
            <p className=" text-rose-600">
              {Math.floor((admin.warn.length / config.maxWarn) * 100)}%
            </p>
            {textFormater(
              Math.floor((admin.warn.length / config.maxWarn) * 100)
            )}
          </div>
        </div>
        <div className="flex-1 glass_card_stone mx-0 sm:mx-4 md:my-0"></div>
        <div className=" py-1 px-2 text-white flex-col justify-between glass_card_stone my-2 md:my-0">
          <div className="flex justify-center ">
            <Gauge
              width={200}
              height={200}
              value={levelForamter(admin.balance.AC).remainingAC}
              startAngle={-110}
              endAngle={110}
              valueMax={levelForamter(admin.balance.AC).nextLevelAC}
              sx={{
                [`& .${gaugeClasses.valueText}`]: {
                  fontSize: 20,
                  transform: "translate(0px, 0px)",
                },
                [`& .${gaugeClasses.valueArc}`]: {
                  fill: "",
                },
                [`& .${gaugeClasses.referenceArc}`]: {
                  fill: "gray",
                },
              }}
              text={({ value, valueMax }) => ""}
            />
          </div>

          <div className="w-full text-center my-3">
            <p className="text-sm text-rose-600">
              {levelForamter(admin.balance.AC).remainingAC}/
              {levelForamter(admin.balance.AC).nextLevelAC}
            </p>
            <p className="text-gray-400">
              <span className="text-green-500">
                {levelForamter(admin.balance.AC).mande}
              </span>
              AC تا لول آپ شدن
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
