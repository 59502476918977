import { useEffect, useRef, useState, useContext } from "react";
import axios from "../../../config/axios";
import { useNotification } from "../../include/notif/hook";
import Loading from "../../include/loading/loading";
import ERR from "../../include/err/message";
import Hint from "../../include/hint/hint";
import Toman from "../../include/textForamt/toman";
import AdminContext from "../../../context/adminContext";
import { useConfirm } from "../../include/confrim/hook";
const Exchange = () => {
  const [showConfirm, confirmComponent] = useConfirm();
  const [transfer, settransfer] = useState(null);
  const countInput = useRef();
  const [err, setErr] = useState(null);
  const [showNotification, NotificationComponent] = useNotification();
  const adminContext = useContext(AdminContext);

  useEffect(() => {
    async function load() {
      axios
        .get("/admin/transfer-list")
        .then(async (res) => {
          if (res && res.data.message === "ok") {
            settransfer(res.data.data);
          } else {
            // setErr(res.data.message);
          }
        })
        .catch((err) => {
          if (err.response) {
            setErr(err.response.data.message);
          } else {
            setErr(err.message);
          }
        });
    }
    load();
  }, []);

  const HandelTransfer = (type) => {
    let count = countInput.current.value;

    if (count === "" || !count) {
      return showNotification("مقدار نمیتواند خالی باشد", "خطا", 5, "err");
    }
    showConfirm(
      "آیا مطعمن هستید ؟",
      `${
        type === "user"
          ? "انتقال به پنل کاربری"
          : type === "ac"
          ? "تبدیل به ای سی"
          : type === "coin"
          ? "تبدیل به کوین"
          : "واریز به کارت"
      }`,
      () => {
        axios
          .post("/admin/transfer", { count, type })
          .then(async (res) => {
            if (res && res.data.message === "ok") {
              settransfer([res.data.data, ...transfer]);
              type !== "bank"
                ? showNotification("با موفقیت انجام شد", "موفق", 5, "ok")
                : showNotification(
                    "درخواست ثبت شد لطفا صبور باشید",
                    "موفق",
                    5,
                    "ok"
                  );
              countInput.current.value = "";
              if (Notification.permission !== "granted") {
                await Notification.requestPermission();
              }
              if (Notification.permission === "granted") {
                new Notification(
                  "شما نوتیف جدید دارید لطفا پنل کاربری را چک کنید"
                );
              }
            } else {
              showNotification(res.data.message, "خطا", 5, "err");
            }
          })
          .catch((err) => {
            if (err.response) {
              showNotification(err.response.data.message, "خطا", 5, "err");
            } else {
              showNotification(err.message, "خطا", 5, "err");
            }
          });
      }
    );
  };

  if (err) {
    return <ERR msg={err} />;
  }

  return (
    <div className="flex-1 mt-10 w-full flex flex-col justify-center items-center transition-all duration-300">
      {NotificationComponent}
      {confirmComponent}
      <div className="glass_card_rose w-full xl:w-7/12 flex flex-col-reverse md:flex-row justify-center items-center">
        <div className="flex-[75%] flex-col p-5 ">
          <p className="text-right sm:text-center text-gray-500 mb-1 sm:mb-4 text-sm">
            موجودی :{" "}
            <span className="text-green-500">
              <Toman price={adminContext.balance.cash} />
            </span>
          </p>
          <div className="w-full flex flex-col mb-2 sm:mb-2">
            <div className="flex flex-col sm:flex-row justify-between ">
              <p className="mb-1 sm:mb-4 text-sm text-gray-500">
                قیمت هر عدد کوین=~{" "}
                <span className="text-green-600 text-base">100 تومان</span>
              </p>
              <p className=" text-sm text-gray-500">
                هر 50 ای سی:{" "}
                <span className="text-green-600 text-base">10،000 تومان</span>
              </p>
            </div>
          </div>
          <input
            type="text"
            className="input w-full"
            placeholder="مبلغ را به تومان وارد کنید"
            ref={countInput}
          />
          <div className="flex justify-between my-5 flex-row flex-wrap">
            <button
              onClick={() => {
                HandelTransfer("coin");
              }}
              className="text-sm btn btn-rose flex-[40%]  sm:flex-[20%]"
            >
              تبدیل به PNG Coin
            </button>
            <button
              onClick={() => {
                HandelTransfer("ac");
              }}
              className="text-sm btn btn-rose flex-[40%] sm:flex-[20%] mr-2"
            >
              تبدیل به AC
            </button>

            <button
              onClick={() => {
                HandelTransfer("bank");
              }}
              className="text-sm  btn btn-rose flex-[40%] sm:flex-[20%] mt-5 sm:mt-0 ml-2 sm:mr-2"
            >
              واریز به کارت بانکی
            </button>

            <button
              onClick={() => {
                HandelTransfer("user");
              }}
              className="text-sm btn btn-rose  flex-[40%] sm:flex-[20%] mt-5 sm:mt-0"
            >
              واریز به پنل کاربری
            </button>
          </div>
        </div>

        <div className="flex-[25%] justify-end items-center ">
          <img
            src={`${process.env.PUBLIC_URL}/img/logo/cards.png`}
            className="wml-2"
            alt=""
          />
        </div>
      </div>
      {
        <Hint
          hints={[
            "با تبدیل موجودی حساب به کوین PNG میتوانید در سرمایه گذاری شرکت کنید و بعد از بالا رفتن و باز شدن خرید و فروش کوین آن را به فروش بگذارید و یا میتوانید با گرفتن آن از کد های هدیه ، کد های تخفیف و یا گردونه ها استفاده کنید.",
            "با تبدیل موجودی حساب به AC سطح پنل ادمینی شما ارتقا پیدا میکند و اولویت بیشتری در گرفتن سفارشات و مدیریت بیشتری در تیم و سایت پیدا میکنید",
            "با انتقال موجودی به پنل کاربری میتوانید با موجودی انتقال یافته از شاپ خرید انجام دهید",
            "با انتخاب انتقال به کارت بانکی موجودی  حساب شما به کارت بانکی ثبت شده در تیم واریز میشود",
            "3 مورد اول همگی با هدیه همراه هستند ولی مورد اخر بدون هدیه و هر 12 شب انتقال انجام میشود",
          ]}
        />
      }
      <div className="w-full xl:w-7/12  h-[350px] overflow-auto glass_card_rose mb-10">
        {!transfer ? (
          <Loading center={false} mt={5} />
        ) : (
          <>
            {transfer.length === 0 ? (
              <p className="mt-5 text-center">
                تا به الان تراکنشی انجام ندادید
              </p>
            ) : (
              <table className="w-full text-center">
                <thead>
                  <tr className="bg-rose-800 rounded-2xl text-white text-sm">
                    <th className="p-2">تاریخ</th>
                    <th className="p-2">مقصد</th>
                    <th className="p-2">پاداش</th>
                    <th className="p-2">مقدار</th>
                    <th className="p-2">وضعیت</th>
                  </tr>
                </thead>
                <tbody>
                  {transfer.map((t, i) => (
                    <tr key={i} className="p-3 text-gray-300 text-sm">
                      <td className="pr-3">{t.date}</td>
                      <td className="pr-3">{t.to}</td>
                      <td className="pr-3">{t.reward}</td>
                      <td className="pr-3">
                        <Toman price={t.price} />
                      </td>
                      {t.status === "pending" ? (
                        <td className="pr-3 text-orange-500">در حال انجام</td>
                      ) : (
                        <td className="pr-3 text-green-500">انجام شده</td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Exchange;
