const Hint = ({hints , w}) => {
  return (
    <div className="w-full xl:w-7/12 glass_card_stone border-2 border-dashed border-rose-700 my-5 p-3">
      <p className="text-base md:text-xl text-red-500">نکات مهم :</p>
      <div className="mt-1 mr-5 text-xs md:text-base py-2">
        {hints.map((l, i) => {
          return (
            <li key={i} className="my-4 md:my-1">
              {l}
            </li>
          );
        })}
      </div>
    </div>
  );
};

export default Hint;
