import { useState } from "react";
import axios from "../../../config/axios";
import { useFormik } from "formik";
import Input from "./input";
import * as yup from "yup";
import { useNotification } from "../../include/notif/hook";

import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_en from "react-date-object/locales/persian_fa";
let lists = [
  "شما پس از ثبت نام توسط ادامین اهراز هویت خواهید شد و بعد از تایید رول و دسترسی مربوطه را دریافت خواهید کرد .",
  "نام ، نام خانوادگی , آدرس باید به صورت فارسی تایپ شود و اعداد را به صورت انگلیسی وارد کنید .",
  "پس از ثبت نام باید تمرکزتون رو یک تیم باشد در صورت مشاهده اخراج خواهید شد .",
  "درصورت اهراز نشدن و یا خارج شدن از تیم تمام اطلاعات شما کاملا پاک خواهد شد .",
  "درصورتی که اطلاعات صحیح وارد نکنید تایید نخواهید شد.",
  "بهتر است قبل از ثبت نام از ادمین مربوطه مشاوره بگیرید.",
];

export const CreateLicense = () => {
  let [btnStat, setBtnstat] = useState({ sending: false });
  const [showNotification, NotificationComponent] = useNotification();

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      birthdate: "",
      cardNumber: "",
      cardOwner: "",
      address: "",
      discord: "",
      instagram: "",
      rules: "",
    },

    onSubmit: async (v) => {
      setBtnstat({ sending: true });
      axios
        .post("/user/register/admin", v)
        .then((res) => {
          if (res.data.message === "ok") {
            setBtnstat({ sending: false });
            showNotification(
              "اطلاعات شما ثبت شد منظر تایید شدن باشید",
              "موفق",
              7,
              "ok"
            );
          } else {
            showNotification(res.data.message, "خطا", 5, "err");
            console.error("Error: ", res.data.message);
            setBtnstat({ sending: false });
          }
        })
        .catch((err) => {
          if (err.response) {
            showNotification(err.response.data.message, "خطا", 5, "err");
          } else {
            showNotification(err.message, "خطا", 5, "err");
          }

          console.error("Error: ", err);
          setBtnstat({ sending: false });
        });
    },

    validationSchema: yup.object({
      rules: yup
        .boolean()
        .transform((value, originalValue) => {
          return Array.isArray(originalValue) && originalValue.includes("on");
        })
        .oneOf([true], "باید شرایط و قوانین را بپذیرید"),
      firstname: yup.string().required("نام کاربر نمیتواند خالی باشد"),
      lastname: yup.string().required("نام کاربر نمیتواند خالی باشد"),
      birthdate: yup.string().required("تاریخ تولد نمیتواند خالی باشد"),
      cardNumber: yup
        .string()
        .length(16, "شماره کارت باید 16 رقم باشد")
        .required("شماره کارت نمیتواند خالی باشد"),
      cardOwner: yup.string().required("نام کارت نمیتواند خالی باشد"),
      address: yup.string().required("ادرس نمی تواند خالی باشد"),
      discord: yup.string().required("ایدی دیسکورد نمی تواند خالی باشد"),
      instagram: yup.string().required("ایدی اینستا نمی تواند خالی باشد"),
    }),
  });

  const handelDateForamat = (date) => {
    const persianDigits = "۰۱۲۳۴۵۶۷۸۹";
    const arabicDigits = "٠١٢٣٤٥٦٧٨٩";

    return date
      .replace(/[۰-۹]/g, (w) => persianDigits.indexOf(w))
      .replace(/[٠-٩]/g, (w) => arabicDigits.indexOf(w));
  };
  return (
    <div className="flex-1 mt-5 w-full flex flex-col items-center">
      {NotificationComponent}
      {console.log(formik.errors)}
      <div className="w-full md:w-8/12 xl:w-6/12 glass_card_stone border-2 border-dashed border-rose-700  p-3">
        <p className="text-base md:text-xl text-red-500">نکات مهم :</p>
        <div className="mt-1 mr-5 text-xs md:text-base py-2">
          {lists.map((l, i) => {
            return (
              <li key={i} className="my-4 md:my-1">
                {l}
              </li>
            );
          })}
        </div>
      </div>

      <form
        onSubmit={formik.handleSubmit}
        action=""
        className="my-10 w-full md:w-8/12 xl:w-6/12 glass_card_stone p-3 flex flex-col items-center"
      >
        <p className="animate-bounce my-5 text-teal-400 md:text-xl">
          فرم ثبت نام در تیم
        </p>

        <div className="my-2 w-full flex flex-col items-center md:flex-row md:justify-around">
          <Input
            name="نام"
            type="text"
            id="firstname"
            onChange={formik.handleChange}
          ></Input>
          <Input
            name=" نام خانوادگی"
            type="text"
            id="lastname"
            onChange={formik.handleChange}
          ></Input>

          <div className="w-full flex justify-center mt-1">
            <DatePicker
              placeholder="تاریخ تولد"
              inputClass="input w-full"
              id="birthdate"
              name="birthdate"
              calendar={persian}
              locale={persian_en}
              calendarPosition="bottom-right"
              onChange={(value) => {
                const formattedDate = value?.format?.("YYYY/MM/DD");
                const englishDate = handelDateForamat(formattedDate);
                formik.setFieldValue("birthdate", englishDate);
              }}
            />
          </div>
        </div>

        <div className="my-2 w-full flex flex-col items-center md:flex-row md:justify-around">
          <Input
            name="شماره کارت"
            type="text"
            id="cardNumber"
            onChange={formik.handleChange}
          ></Input>
          <Input
            name="نام صاحب شماره کارت"
            type="text"
            id="cardOwner"
            onChange={formik.handleChange}
          ></Input>
        </div>

        <div className="my-2 w-full flex flex-col items-center md:flex-row md:justify-around">
          <Input
            name="ایدی دیسکورد"
            type="text"
            id="discord"
            onChange={formik.handleChange}
          ></Input>
          <Input
            name="ایدی اینستاگرام"
            type="text"
            id="instagram"
            onChange={formik.handleChange}
          ></Input>
        </div>

        <div className="my-2 w-full flex flex-col items-center md:flex-row md:justify-around">
          <textarea
            rows="3"
            id="address"
            name="address"
            className="input w-full"
            placeholder="ادرس  دقیق منزل (برای دریافت برخی از پک های تیم)"
            onChange={formik.handleChange}
          ></textarea>
        </div>

        <div className="my-2 w-full flex justify-start">
          <input
            type="checkbox"
            className="mx-3"
            id="rules"
            name="rules"
            onChange={formik.handleChange}
          />
          <p> قوانین رو به صورت کامل مطالعه کردم</p>
        </div>
        <div className="my-2 w-full flex justify-around">
          <button
            disabled={btnStat.sending}
            className="btn btn-rose w-32 my-5 flex items-center justify-center"
            type="submit"
          >
            {btnStat.sending ? (
              <div className="w-6 h-6 border-b-2 border-r-2 rounded-full animate-spin"></div>
            ) : (
              "ثبت نام"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateLicense;
