import { useEffect, useState } from "react";
import axios from "../../../config/axios";
import { useNotification } from "../../include/notif/hook";
import Loading from "../../include/loading/loading";
import ERR from "../../include/err/message";
import Toman from "../../include/textForamt/toman";
import cardNumber from "../../include/textForamt/cardnumber";
import { useConfirm } from "../../include/confrim/hook";

export const TransferRequests = () => {
  const [showConfirm, confirmComponent] = useConfirm();
  const [requests, setRequests] = useState([]);
  const [err, setErr] = useState(null);
  const [showNotification, NotificationComponent] = useNotification();
  const [inputValues, setInputValues] = useState({});

  useEffect(() => {
    async function load() {
      axios
        .get("/admin/transfer-requests")
        .then((res) => {

          if (res && res.data.message === "ok") {
            setRequests(res.data.data);
          } else {
            setErr(res.data.message);
          }
        })
        .catch((err) => {
          if (err.response) {
            setErr(err.response.data.message);
          } else {
            setErr(err.message);
          }
        });
    }

    load();
  }, []);

  const handleInputChange = (id, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
  };

  const handleSubmit = (id) => {
    const code = inputValues[id];
    if (!code || code.trim() === "") {
      showNotification("لطفا شماره پیگیری را وارد کنید", "خطا", 5, "err");
      return;
    }
    DoneTransfer(id, code);
  };

  const DoneTransfer = (id, code) => {
    showConfirm(
      "آیا مطمعن هستید تراکنش را نهایی کنید؟",
      "نهایی کردن تراکنش",
      () => {
        axios
          .put("/admin/transfer-done", { id, code })
          .then((res) => {
            if (res && res.data.message === "ok") {
              setRequests(res.data.data);
              setInputValues((prevValues) => ({
                ...prevValues,
                [id]: "", // Clear the input field after successful submission
              }));
              showNotification("تراکنش با موفقیت انجام شد", "موفق", 5, "ok");
            } else {
              setErr(res.data.message);
            }
          })
          .catch((err) => {
            if (err.response) {
              setErr(err.response.data.message);
            } else {
              setErr(err.message);
            }
          });
      }
    );
  };

  const copyHandel = (text) => {
    navigator.clipboard.writeText(text);
    showNotification("متن مورد نظر کپی شد", "موفق", 5, "ok");
  };

  if (err) {
    return <ERR msg={err} />;
  }
  if (requests.length === 0) {
    return <Loading center={true} />;
  }
  return (
    <div className="flex flex-col items-center mt-5 w-full p-3">
      {NotificationComponent}
      {confirmComponent}
      <div className="w-full flex justify-center items-center flex-col ">
        {requests.map((r) => (
          <div
            className="w-full md:w-7/12 glass_card_rose p-5 my-5"
            key={r._id}
          >
            <div className="flex justify-between text-[12px] md:text-sm">
              <p>
                مبلغ حقوق :{" "}
                <span className="text-red-600">
                  <Toman price={r.price} />
                </span>
              </p>
              <p className="text-[12px] md:text-sm text-rose-500">{r.createdAt}</p>
            </div>
            <div className="flex flex-col items-center my-10 ">
              <p
                className="text-[12px] sm:text-base md:text-xl hover:text-blue-500 cursor-pointer"
                onClick={() => copyHandel(r.owner.card.number)}
              >
                {cardNumber(r.owner.card.number)}
              </p>
              <p className="text-sm text-gray-400">{r.owner.card.name}</p>
            </div>
            <div className="flex flex-col items-center w-full">
              <input
                type="text"
                className="input w-full"
                placeholder="شماره پیگیری تراکنش"
                value={inputValues[r._id] || ""}
                onChange={(e) => handleInputChange(r._id, e.target.value)}
              />
              <button
                className="btn btn-green mt-3"
                onClick={() => handleSubmit(r._id)}
              >
                تکمیل
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TransferRequests;
