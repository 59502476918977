import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Component } from 'react';
import AdminMain from './components/admins/mian'
import AuthMain from "./components/auth/main"
import HomeMin from "./components/home/main"
import UserMain from "./components/users/main"

class App extends Component {

    render() {
        return (

            <Router>
                <Routes>
                    <Route path="/user/*" element={<UserMain />} />
                    <Route path="/admin/*" element={<AdminMain />} />
                    <Route path="/account/*" element={<AuthMain />} />
                    <Route path="/" element={<HomeMin />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </Router>


        );
    }
}

export default App;