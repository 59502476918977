import { useEffect, useRef, useState } from "react";
import axios from "../../../config/axios";
import { useNotification } from "../../include/notif/hook";
import { useParams } from "react-router-dom";
import Loading from "../../include/loading/loading";
import ERR from "../../include/err/message";
import levelForamter from "../../include/textForamt/level";
import Toman from "../../include/textForamt/toman";
import { useConfirm } from "../../include/confrim/hook";
import { BsFillTrashFill } from "react-icons/bs";

export const Manage = () => {
  const [showConfirm, confirmComponent] = useConfirm();
  const [showNotification, NotificationComponent] = useNotification();
  const [err, setErr] = useState(null);
  let [sending, setSending] = useState(false);
  let [admin, setAdmin] = useState(null);
  let { id } = useParams();
  const results = useRef();
  const notif = useRef();
  const typeNotif = useRef();

  const warninfo = useRef();
  const warncount = useRef();
  const warntype = useRef();

  const giftinfo = useRef();
  const giftcount = useRef();
  const gifttype = useRef();
  useEffect(() => {
    const loadAdmins = async () => {
      try {
        const res = await axios.get("/admin/admin/manage/" + id);
        if (res.data.message === "ok") {
          setAdmin(res.data.data);
        } else {
          showNotification(res.data.message, "خطا", 5, "err");
          setErr(res.data.message);
        }
      } catch (error) {
        setErr(error.response.data.message);
        console.error("Error fetching admins:", error);
      }
    };

    loadAdmins();
  }, []);

  const HandelAccount = (type, aid) => {
    let result = results.current.value;
    if (result || result !== "") {
      showConfirm(
        "آیا مطمئن هستید؟",
        `تایید ${
          type === "ban" ? "اخراج" : type === "sus" ? "تعلیق" : "فعالسازی"
        }`,
        () => {
          setSending(true);
          axios
            .put("/admin/account-status", { type: type, id: aid })
            .then((r) => {
              if (r && r.data.message === "ok") {
                showNotification(
                  "وضعیت حساب کاربری با موفقیت تغییر یافت",
                  "موفق",
                  5,
                  "ok"
                );
                setSending(false);
                results.current.value = "";
                setAdmin(r.data.data);
              } else {
                showNotification(r.data.message, "خطا", 5, "err");
                setSending(false);
              }
            })
            .catch((e) => {
              showNotification(e.response.data.message, "خطا", 5, "err");
              setSending(false);
            });
        }
      );
    } else {
      showNotification("دلیل نمی تواند خالی باشد", "خطا", 5, "err");
    }
  };

  const handelNotif = (id) => {
    let nv = notif.current.value;
    let tnv = typeNotif.current.value;

    if (nv === "" || tnv === "") {
      return showNotification(
        "دلیل نوتیف و تایپ نباید خالی باشد",
        "خطا",
        5,
        "err"
      );
    }

    if (tnv !== "normal" && tnv !== "important") {
      return showNotification(
        "تایپ نباید چیزی به جز normal , important باشد",
        "خطا",
        5,
        "err"
      );
    }

    showConfirm("آیا مطمئن هستید؟", `تایید ارسال نوتیف`, () => {
      setSending(true);
      axios
        .post("/admin/send-notif", { text: nv, id: id, type: tnv })
        .then((r) => {
          if (r && r.data.message === "ok") {
            notif.current.value = "";
            typeNotif.current.value = "";

            setAdmin(r.data.data);
            showNotification("نوتیف با موفقیت ارسال شد", "موفق", 5, "ok");
            setSending(false);
          } else {
            showNotification(r.data.message, "خطا", 5, "err");
            setSending(false);
          }
        })
        .catch((e) => {
          showNotification(e.response.data.message, "خطا", 5, "err");
          setSending(false);
        });
    });
  };

  const handelWarn = (id) => {
    let warnI = warninfo.current.value;
    let warnT = warntype.current.value;
    let warnC = warncount.current.value;
    if (warnI === "" || warnT === "" || warnC === "" || warnC === 0) {
      return showNotification(
        "دلیل ، تایپ و تعداد نباید خالی باشد",
        "خطا",
        5,
        "err"
      );
    }

    if (warnT !== "ac" && warnT !== "coin") {
      return showNotification(
        "تایپ نباید چیزی به جز ac , coin باشد",
        "خطا",
        5,
        "err"
      );
    }

    showConfirm("آیا مطمئن هستید؟", `تایید وارن`, () => {
      setSending(true);
      axios
        .post("/admin/send-warn", {
          text: warnI,
          id: id,
          type: warnT,
          count: warnC,
        })
        .then((r) => {
          if (r && r.data.message === "ok") {
            warninfo.current.value = "";
            warntype.current.value = "";
            warncount.current.value = "";
            showNotification("نوتیف با موفقیت ارسال شد", "موفق", 5, "ok");
            setSending(false);
            setAdmin(r.data.data);
          } else {
            showNotification(r.data.message, "خطا", 5, "err");
            setSending(false);
          }
        })
        .catch((e) => {
          showNotification(e.response.data.message, "خطا", 5, "err");
          setSending(false);
        });
    });
  };

  const handelGift = (id) => {
    let giftI = giftinfo.current.value;
    let giftT = gifttype.current.value;
    let giftC = giftcount.current.value;
    if (giftI === "" || giftT === "" || giftC === "" || giftC === 0) {
      return showNotification(
        "دلیل ، تایپ و تعداد نباید خالی باشد",
        "خطا",
        5,
        "err"
      );
    }

    if (giftT !== "ac" && giftT !== "coin") {
      return showNotification(
        "تایپ نباید چیزی به جز ac , coin باشد",
        "خطا",
        5,
        "err"
      );
    }

    showConfirm("آیا مطمئن هستید؟", `تایید هدیه`, () => {
      setSending(true);
      axios
        .post("/admin/send-gift", {
          text: giftI,
          id: id,
          type: giftT,
          count: giftC,
        })
        .then((r) => {
          if (r && r.data.message === "ok") {
            giftinfo.current.value = "";
            gifttype.current.value = "";
            giftcount.current.value = "";
            showNotification("هدیه با موفقیت ارسال شد", "موفق", 5, "ok");
            setSending(false);
            setAdmin(r.data.data);
          } else {
            showNotification(r.data.message, "خطا", 5, "err");
            setSending(false);
          }
        })
        .catch((e) => {
          showNotification(e.response.data.message, "خطا", 5, "err");
          setSending(false);
        });
    });
  };

  const DelWarnHandel = (id, warnid) => {
    showConfirm("آیا مطمئن هستید؟", `تایید حذف وارن `, () => {
      axios
        .post("/admin/del-warn", { id: id, warnid: warnid })
        .then(async (res) => {
          if (res && res.data.message === "ok") {
            showNotification("با موفقیت پاک شد", "موفق", 5, "ok");
            setAdmin(res.data.data);
          } else {
            showNotification(res.data.message, "خطا", 5, "err");
          }
        })
        .catch((err) => {
          const message = err.response
            ? err.response.data.message
            : err.message;
          showNotification(message, "خطا", 5, "err");
        });
    });
  };
  const copyHandel = (text) => {
    navigator.clipboard.writeText(text);
    showNotification("ایدی ادمین کپی شد", "موفق", 5, "ok");
  };

  if (err) {
    return <ERR msg={err} />;
  }

  if (!admin) {
    return <Loading center={true} />;
  }

  return (
    <div className="flex flex-col items-center mt-5 w-full p-3 ">
      {NotificationComponent}
      {confirmComponent}
      <div
        className="relative w-full  md:w-9/12 h-[100px] sm:h-[300px] shadow-lg hover:scale-[1.02] transition-all duration-300 "
        onClick={() => copyHandel(admin._id)}
      >
        <div className="absolute top-0 left-0 w-full h-full ">
          <img
            src={`${process.env.PUBLIC_URL}${admin.userId.banner}`}
            alt="Background"
            className="object-cover w-full h-full rounded-2xl"
          />
        </div>
        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-black/25 to-rose-950/90 rounded-2xl"></div>
        <div className="relative flex items-center justify-between px-2 py-6 top-0 sm:top-36">
          <div className="flex items-center">
            <div
              className={`w-14 h-14 sm:w-28 sm:h-28 rounded-full overflow-hidden border-4 border-${
                admin.active ? "green" : "red"
              }-500 animate-pulse`}
            >
              <img
                src={`${process.env.PUBLIC_URL}${admin.userId.profile}`}
                alt="progfile"
                className="object-cover w-full h-full"
              />
            </div>

            <div className="mr-2 sm:mr-4 text-white">
              <h1 className="text-sm font-bold sm:text-xl">
                {admin.firstname}
              </h1>
              <p className="text-[10px] sm:text-sm text-rose-600">
                {admin.role.admin}
              </p>
              <p className="text-[10px] sm:text-sm text-rose-600">
                {admin.role.work}
              </p>

              {/* <p className="text-[10px] sm:text-sm text-rose-600">تاریخ عضویت :  1 1 1 </p> */}
            </div>
          </div>
        </div>
      </div>

      <div className="w-full  md:w-9/12 mt-5 py-5 glass_card_stone flex flex-col sm:flex-row justify-around items-center text-[12px] sm:text-base">
        <div className="flex justify-center items-center flex-col">
          <img
            className="w-20 sm:w-10"
            src={`${process.env.PUBLIC_URL}/img/logo/Keyboard.png`}
            alt=""
          />
          <span className="mt-2 ">{<Toman price={admin.balance.cash} />}</span>
        </div>

        <div className="flex justify-center items-center flex-col">
          <img
            className="w-20 sm:w-10 mt-10 sm:mt-0"
            src={`${process.env.PUBLIC_URL}/img/logo/coin.png`}
            alt=""
          />
          <span className="mt-2  ">{admin.userId.balance.PNG_Coin}</span>
        </div>
        <div className="flex justify-center items-center flex-col">
          <img
            className="w-20 sm:w-10 mt-10 sm:mt-0"
            src={`${process.env.PUBLIC_URL}/img/logo/Verified.png`}
            alt=""
          />
          <span className="mt-2 ">{levelForamter(admin.balance.AC).level}</span>
        </div>
        <div className="flex justify-center items-center flex-col">
          <img
            className="w-20 sm:w-10 mt-10 sm:mt-0"
            src={`${process.env.PUBLIC_URL}/img/logo/Chart.png`}
            alt=""
          />
          <span className="mt-2">{admin.sell.count}</span>
        </div>
        <div className="flex justify-center items-center flex-col">
          <img
            className="w-20 sm:w-10 mt-10 sm:mt-0"
            src={`${process.env.PUBLIC_URL}/img/logo/amar.png`}
            alt=""
          />
          <span className="mt-2">{admin.sell.price}</span>
        </div>
      </div>

      <div className="w-full md:w-9/12 mt-5  flex justify-center md:justify-between flex-wrap text-sm sm:text-base">
        <div className="glass_card_stone h-[100px] w-full xl:w-[300px] p-2 flex justify-between ">
          <div className="w-full text-center flex flex-col justify-between">
            <p className="text-sm text-gray-500">دیسکورد</p>
            <p className="text-rose-500">{admin.media.discord}</p>
          </div>
          <img
            src={`${process.env.PUBLIC_URL}/img/logo/messenger.png`}
            alt=""
            className="w-20 h-20"
          />
        </div>
        <div className="glass_card_stone h-[100px] w-full xl:w-[300px]  p-2 flex justify-between mt-3 xl:mt-0">
          <div className="w-full text-center flex flex-col justify-between">
            <p className="text-sm text-gray-500">شماره تماس</p>
            <p className="text-rose-500">{admin.userId.phone}</p>
          </div>
          <img
            src={`${process.env.PUBLIC_URL}/img/logo/info.png`}
            alt=""
            className="w-20 h-20"
          />
        </div>
        <div className="glass_card_stone h-[100px] w-full xl:w-[300px]  p-2 flex justify-between my-3 xl:my-0">
          <div className="w-full text-center flex flex-col justify-between">
            <p className="text-sm text-gray-500">ایمیل</p>
            <p className="text-rose-500 text-[12px]">
              {admin.userId.email.split("@", 1)}
            </p>
          </div>
          <img
            src={`${process.env.PUBLIC_URL}/img/logo/info.png`}
            alt=""
            className="w-20 h-20"
          />
        </div>
        <div className="glass_card_stone h-[100px]  w-full xl:w-[300px]  p-2 flex justify-between mb-3 xl:mb-0">
          <div className="w-full text-center flex flex-col justify-between">
            <p className="text-sm text-gray-500">اینستاگرام</p>
            <p className="text-rose-500">{admin.media.instagram}</p>
          </div>
          <img
            src={`${process.env.PUBLIC_URL}/img/logo/Instagram.png`}
            alt=""
            className="w-20 h-20"
          />
        </div>
      </div>

      <div className="glass_card_stone max-h-[300px] overflow-x-auto w-full md:w-9/12  mt-5  flex justify-between p-5">
        <div className="w-full text-center flex flex-col justify-between">
          <p className="text-gray-500">
            {" "}
            اخطار ها{" "}
            <span className="text-rose-700">({admin.warn.length})</span>
          </p>

          <div>
            {admin.warn.length === 0 ? (
              <div className="mt-5">
                <p className="text-rose-600 text-sm"> این کاربر اخطاری ندارد</p>
              </div>
            ) : (
              <>
                {admin.warn
                  .slice()
                  .reverse()
                  .map((n, i) => (
                    <>
                      <div
                        key={n._id}
                        className="w-full flex justify-between divide-current bg-stone-800/80 rounded-lg p-2 mt-5"
                      >
                        <p className="text-sm">
                          {i + 1}) {n.message}
                        </p>
                        <BsFillTrashFill
                          className="w-7 h-7 text-red-500"
                          onClick={() => DelWarnHandel(admin._id, n._id)}
                        />
                      </div>
                    </>
                  ))}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="w-full md:w-9/12 flex md:justify-between md:flex-row flex-col items-center mt-5">
        <div className="w-full md:h-[230px] md:w-9/12 mt-5 md:mt-0 glass_card_stone items-center flex-col p-2">
          <div className="h-5 bg-rose-900 w-full flex justify-center items-center rounded-md py-4">
            <p>تنظیمات حساب</p>
          </div>

          <div className="flex w-full items-center mt-5 flex-col justify-center">
            <input
              ref={results}
              name="text"
              id="text"
              type="text"
              className="input w-full sm:w-11/12"
              placeholder="دلیل (اخراج و تعلیق و یا فعالسازی)"
            />

            <div className="w-full sm:w-11/12 flex justify-between my-5 flex-wrap items-center">
              <button
                onClick={() => HandelAccount("active", admin._id)}
                type="submit"
                className="btn btn-green w-auto sm:w-20"
                value="active"
                disabled={sending}
              >
                فعال
              </button>
              <button
                onClick={() => HandelAccount("sus", admin._id)}
                type="submit"
                className="btn btn-yellow w-auto sm:w-20"
                value="sus"
                disabled={sending}
              >
                تعلیق
              </button>
              <button
                onClick={() => HandelAccount("ban", admin._id)}
                type="submit"
                className="btn btn-red w-auto sm:w-20"
                value="ban"
                disabled={sending}
              >
                اخراج
              </button>
            </div>
          </div>
        </div>
        <div className="w-full h-auto md:h-[230px] md:w-9/12 mt-5 md:mt-0 glass_card_stone items-center flex-col p-2 mr-0 md:mr-5">
          <div className="h-5 bg-rose-900 w-full flex justify-center items-center rounded-md py-4 ">
            <p>ارسال نوتیف</p>
          </div>

          <div className="flex w-full items-center mt-5 flex-col justify-center">
            <input
              ref={notif}
              name="text"
              id="text"
              type="text"
              className="input w-full sm:w-11/12"
              placeholder="متن اعلان را وارد کنید"
            />

            <div className="w-full sm:w-11/12 flex flex-wrap items-center mt-2">
              <input
                ref={typeNotif}
                name="text"
                id="text"
                type="text"
                className="input flex-[75%] ml-2"
                placeholder="تایپ اعلان (Important or noraml)"
              />

              <button
                onClick={() => handelNotif(admin._id)}
                type="submit"
                className="btn btn-green flex-[20%] my-2"
                value="active"
                disabled={sending}
              >
                ارسال
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full md:w-9/12 flex md:justify-between md:flex-row flex-col items-center mt-5 mb-10">
        <div className="w-full md:w-9/12 mt-5 md:mt-0 glass_card_stone items-center flex-col p-2 ">
          <div className="h-5 bg-rose-900 w-full flex justify-center items-center rounded-md py-4">
            <p>ارسال هدیه</p>
          </div>

          <div className="flex w-full items-center mt-5 flex-col justify-center">
            <input
              ref={giftinfo}
              name="text"
              id="text"
              type="text"
              className="input w-full sm:w-11/12"
              placeholder="متن هدیه را وارد کنید"
            />

            <div className="w-full sm:w-11/12 flex flex-wrap items-center mt-2">
              <input
                ref={gifttype}
                name="text"
                id="text"
                type="text"
                className="input flex-[15%] ml-2"
                placeholder="ac یا coin اضافه شود"
              />

              <input
                ref={giftcount}
                name="text"
                id="text"
                type="text"
                className="input flex-[15%] ml-2"
                placeholder="تعداد"
              />

              <button
                onClick={() => handelGift(admin._id)}
                type="submit"
                className="btn btn-green flex-[20%] my-2"
                value="active"
                disabled={sending}
              >
                ارسال
              </button>
            </div>
          </div>
        </div>
        <div className="w-full p-2 md:w-9/12 mt-5 md:mt-0 glass_card_stone items-center flex-col  mr-0 md:mr-5">
          <div className="h-5 bg-rose-900 w-full flex justify-center items-center rounded-md py-4">
            <p>ارسال اخطار</p>
          </div>

          <div className="flex w-full items-center mt-5 flex-col justify-center">
            <input
              ref={warninfo}
              name="text"
              id="text"
              type="text"
              className="input w-full sm:w-11/12"
              placeholder="متن اخطار را وارد کنید"
            />

            <div className="w-full sm:w-11/12 flex flex-wrap items-center mt-2">
              <input
                ref={warntype}
                name="text"
                id="text"
                type="text"
                className="input flex-[15%] ml-2"
                placeholder="کم شدن  (ac , coin)"
              />

              <input
                ref={warncount}
                name="text"
                id="text"
                type="number"
                className="input flex-[15%] ml-2"
                placeholder="تعداد"
              />

              <button
                onClick={() => handelWarn(admin._id)}
                type="submit"
                className="btn my-2 btn-green flex-[20%]"
                value="active"
                disabled={sending}
              >
                ارسال
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Manage;
