const Input = ({ name, id, type, onChange }) => {
  return (
    <div className="w-11/12 flex flex-col my-3">
      <label htmlFor={id} className="text-xs mr-2">
        {name} :
      </label>
      <input
        className="input"
        type={type}
        name={id}
        id={id}
        onChange={onChange}
      />
    </div>
  );
};

export default Input;
