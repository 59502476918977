import React, { useEffect, useState } from "react";
import axios from "../../../config/axios";
import { useNotification } from "../../include/notif/hook";

const Discord = () => {
  const [showNotification, NotificationComponent] = useNotification();
  const [serverInfo, setServerInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [embedDetails, setEmbedDetails] = useState({
    title: "",
    description: "",
    url: "",
    color: "#00e4f5",
    thumbnail: { url: "" },
    fields: [],
    image: { url: "" },
    footer: {
      text: "Powered By AlirezaPNG",
      icon_url: `https://png-community.ir/img/logo/png.png`,
    },
  });

  const [metaData, setMetaData] = useState({
    tag: false,
    normalMessage: false,
    content: "",
    channelId: "",
  });

  const reset = () => {
    setMetaData({
      tag: false,
      normalMessage: false,
      content: "",
      channelId: "",
    });

    setEmbedDetails({
      title: "",
      description: "",
      url: "",
      color: "#00e4f5",
      thumbnail: { url: "" },
      fields: [],
      image: { url: "" },
      footer: {
        text: "",
        icon_url: "",
      },
    });
  };

  const fetchData = () => {
    axios
      .get("/admin/discord-data")
      .then((res) => {
        if (res && res.data.message === "ok") {
          setServerInfo(res.data.data);
        } else {
          console.error(res.data.message);
        }
      })
      .catch((err) => console.error(err.response?.data.message || err.message));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleFieldChange = (index, key, value) => {
    const updatedFields = [...embedDetails.fields];
    updatedFields[index][key] = value;
    setEmbedDetails({ ...embedDetails, fields: updatedFields });
  };

  const addField = () => {
    setEmbedDetails({
      ...embedDetails,
      fields: [...embedDetails.fields, { name: "", value: "", inline: false }],
    });
  };

  const sendEmbedMessage = async () => {
    if (metaData.channelId === "") {
      return showNotification("واردکردن چنل الزامیست", "خطا", 5, "err");
    }

    if (embedDetails.description === "") {
      return showNotification("وارد کردن  توضیحات الزامیست", "خطا", 5, "err");
    }

    setLoading(true);
    axios
      .post("/admin/discord-announce", { embed: embedDetails, data: metaData })
      .then((res) => {
        if (res && res.data.message === "ok") {
          showNotification("با موفقیت ارسال شد", "موفق", 5, "ok");
          reset();
        } else {
          showNotification(res.data.message, "خطا", 5, "err");
        }
        setLoading(false);
      })
      .catch((err) => {
        showNotification(
          err.response?.data.message || err.message,
          "خطا",
          5,
          "err"
        );
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col mt-5 w-full">
      {NotificationComponent}
      <div className="flex items-center glass_card_rose">
        <div
          className={`w-14 h-14 sm:w-28 sm:h-28 rounded-full overflow-hidden border-4 border-${
            serverInfo?.server.avatar ? "green" : "red"
          }-500 animate-pulse`}
        >
          <img
            src={serverInfo?.server.avatar}
            alt="profile"
            className="object-cover w-full h-full"
          />
        </div>
        <div className="mr-2 sm:mr-4 text-white">
          <h1 className="text-sm font-bold sm:text-xl">
            {serverInfo?.server.name}
          </h1>
          <p className="text-[10px] sm:text-sm text-rose-600">
            ممبر : {serverInfo?.server.members}
          </p>
          <p className="text-[10px] sm:text-sm text-rose-600">
            بوست : {serverInfo?.server.boost}
          </p>
        </div>
      </div>

      <div className="w-full flex justify-center items-center mt-5">
        <div className="glass_card w-full md:w-7/12 lg:w-5/12 p-5 flex flex-col items-center">
          <h1 className="mb-5 text-xl">ارسال پیام</h1>

          <select
            className="text-sm sm:text-base backdrop-blur-3xl bg-gradient-to-tr bg-stone-800/80 select-none outline-0 rounded-md border border-rose-900 mt-1 px-2 py-3 text-slate-300 shadow-md shadow-black my-3 w-full"
            value={metaData.channelId}
            onChange={(e) =>
              setMetaData({ ...metaData, channelId: e.target.value })
            }
          >
            <option value="" disabled>
              انتخاب کانال
            </option>
            {serverInfo?.channels.map((c) => (
              <>
                {c.type === 0 || c.type === 5 ? (
                  <option key={c.id} value={c.id}>
                    {c.name}
                  </option>
                ) : null}
              </>
            ))}
          </select>

          <input
            type="text"
            className="input my-3 w-full"
            placeholder="عنوان"
            value={embedDetails.title}
            onChange={(e) =>
              setEmbedDetails({ ...embedDetails, title: e.target.value })
            }
          />
          <textarea
            className="input my-3 w-full"
            placeholder="توضیحات"
            value={embedDetails.description}
            onChange={(e) =>
              setEmbedDetails({ ...embedDetails, description: e.target.value })
            }
          />
          <input
            type="text"
            className="input my-3 w-full"
            placeholder="لینک"
            value={embedDetails.url}
            onChange={(e) =>
              setEmbedDetails({ ...embedDetails, url: e.target.value })
            }
          />
          <input
            type="text"
            className="input my-3 w-full"
            placeholder="لینک تامنیل"
            value={embedDetails.thumbnail.url}
            onChange={(e) =>
              setEmbedDetails({
                ...embedDetails,
                thumbnail: {
                  ...embedDetails.thumbnail,
                  url: e.target.value,
                },
              })
            }
          />
          <input
            type="text"
            className="input my-3 w-full"
            placeholder="لینک عکس"
            value={embedDetails.image.url}
            onChange={(e) =>
              setEmbedDetails({
                ...embedDetails,
                image: { ...embedDetails.image, url: e.target.value },
              })
            }
          />
          <div className="w-full flex  justify-around ">
            <input
              type="text"
              className="input my-3 w-full"
              placeholder="متن فوتر"
              value={embedDetails.footer.text}
              onChange={(e) =>
                setEmbedDetails({
                  ...embedDetails,
                  footer: { ...embedDetails.footer, text: e.target.value },
                })
              }
            />
            <input
              type="text"
              className="input my-3 w-full mr-1"
              placeholder="لینک ایکون"
              value={embedDetails.footer.icon_url}
              onChange={(e) =>
                setEmbedDetails({
                  ...embedDetails,
                  footer: { ...embedDetails.footer, icon_url: e.target.value },
                })
              }
            />
          </div>
          {embedDetails.fields.map((field, index) => (
            <div key={index} className="flex flex-col w-full my-3">
              <input
                type="text"
                className="input my-1"
                placeholder="نام فیلد"
                value={field.name}
                onChange={(e) =>
                  handleFieldChange(index, "name", e.target.value)
                }
              />
              <textarea
                className="input my-1 mb-2 "
                placeholder="مقدار فیلد"
                value={field.value}
                onChange={(e) =>
                  handleFieldChange(index, "value", e.target.value)
                }
              />
              <label>
                <input
                  type="checkbox"
                  checked={field.inline}
                  className="input ml-2"
                  onChange={(e) =>
                    handleFieldChange(index, "inline", e.target.checked)
                  }
                />
                نمایش در یک خط
              </label>
            </div>
          ))}

          <div className="w-full flex items-center mt-5">
            <input
              type="color"
              className="bg-black"
              value={embedDetails.color}
              onChange={(e) =>
                setEmbedDetails({ ...embedDetails, color: e.target.value })
              }
            />
            <label htmlFor="tag" className="mx-3 ">
              <input
                className="input ml-2"
                type="checkbox"
                checked={metaData.tag}
                name=""
                id="tag"
                onChange={(e) =>
                  setMetaData({ ...metaData, tag: e.target.checked })
                }
              />
              تگ
            </label>
            <label htmlFor="normalMessage" className="mx-3">
              <input
                className="input ml-2"
                type="checkbox"
                name=""
                id="normalMessage"
                checked={metaData.normalMessage}
                onChange={(e) =>
                  setMetaData({
                    ...metaData,
                    normalMessage: e.target.checked,
                  })
                }
              />
              پیام ساده
            </label>
          </div>
          <div>
            <button onClick={reset} className="btn btn-rose mt-3">
              ریست
            </button>
            <button
              disabled={loading}
              onClick={sendEmbedMessage}
              className="btn btn-rose mt-12 mx-2"
            >
              {loading ? "درحال ارسال" : "ارسال"}
            </button>
            <button onClick={addField} className="btn btn-rose mt-3">
              فیلد
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Discord;
