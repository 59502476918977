import { Route, Routes } from "react-router-dom";
import Home from "./home";
function AdminMain() {
  return (
    <Routes>
      <Route path="" element={<Home />} />
    </Routes>
  );
}
export default AdminMain;
