import axios from 'axios';

const isProduction = process.env.NODE_ENV === 'production';


const baseURL = isProduction
    ? 'https://api.png-community.ir'
    : 'http://localhost:8080';



export default axios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});
