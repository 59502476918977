const Input = ({ name, id, type, onChange , place }) => {
  return (
    <div className="w-full flex flex-col my-2">
      <label htmlFor={id} className="text-xs mr-2 text-stone-500">
        {name} :
      </label>
      <input
        className="input"
        type={type}
        name={id}
        id={id}
        placeholder={place}
        onChange={onChange}
      />
    </div>
  );
};

export default Input;
