import { useEffect, useContext, useState } from "react";
import axios from "../../../config/axios";
import { useNotification } from "../../include/notif/hook";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loading from "../../include/loading/loading";
import ERR from "../../include/err/message";
import levelForamter from "../../include/textForamt/level";
import AdminContext from "../../../context/adminContext";

export const Profile = () => {
  const navigate = useNavigate();
  const thisAdmin = useContext(AdminContext);
  const [showNotification, NotificationComponent] = useNotification();
  const [err, setErr] = useState(null);
  let [admin, setAdmin] = useState(null);
  let { id } = useParams();
  useEffect(() => {
    const loadAdmins = async () => {
      try {
        const res = await axios.get("/admin/admin/" + id);
        if (res.data.message === "ok") {
          setAdmin(res.data.data);
        } else {
          showNotification(res.data.message, "خطا", 5, "err");
          setErr(res.data.message);
        }
      } catch (error) {
        setErr(error.response.data.message);
        console.error("Error fetching admins:", error);
      }
    };

    loadAdmins();
  }, []);

  const HandelManagePage = (id) => {
    if (
      thisAdmin.role.admin === "PNG-Core" ||
      thisAdmin.role.admin === "Founder"
    ) {
      navigate(`/admin/admins/manage/${id}`);
    } else {
      return null;
    }
  };

  if (err) {
    return <ERR msg={err} />;
  }

  if (!admin) {
    return <Loading center={true} />;
  }

  return (
    <div className="flex flex-col items-center mt-5 w-full p-3">
      {NotificationComponent}
      <div
        className="relative w-full  md:w-9/12 h-[100px] sm:h-[300px] shadow-lg hover:scale-[1.02] transition-all duration-300 "
        onClick={() => HandelManagePage(admin._id)}
      >
        <div className="absolute top-0 left-0 w-full h-full ">
          <img
            src={`${process.env.PUBLIC_URL}${admin.userId.banner}`}
            alt="Background"
            className="object-cover w-full h-full rounded-2xl"
          />
        </div>
        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-black/25 to-rose-950/90 rounded-2xl"></div>
        <div className="relative flex items-center justify-between px-2 py-6 top-0 sm:top-36">
          <div className="flex items-center">
            <div
              className={`w-14 h-14 sm:w-28 sm:h-28 rounded-full overflow-hidden border-4 border-${
                admin.active ? "green" : "red"
              }-500 animate-pulse`}
            >
              <img
                src={`${process.env.PUBLIC_URL}${admin.userId.profile}`}
                alt="progfile"
                className="object-cover w-full h-full"
              />
            </div>

            <div className="mr-2 sm:mr-4 text-white">
              <h1 className="text-sm font-bold sm:text-xl">
                {admin.firstname} {admin.lastname}
              </h1>
              <p className="text-[10px] sm:text-sm text-rose-600">
                {admin.role.admin}
              </p>
              <p className="text-[10px] sm:text-sm text-rose-600">
                {admin.role.work}
              </p>

              {/* <p className="text-[10px] sm:text-sm text-rose-600">
                تاریخ عضویت : 1 1 1{" "}
              </p> */}
            </div>
          </div>
        </div>
      </div>

      <div className="w-full  md:w-9/12 mt-5 py-5 glass_card_stone flex flex-col sm:flex-row justify-around items-center text-[12px] sm:text-base">
        <div className="flex justify-center items-center flex-col">
          <img
            className="w-20 sm:w-10 mt-10 sm:mt-0"
            src={`${process.env.PUBLIC_URL}/img/logo/coin.png`}
            alt=""
          />
          <span className="mt-2  ">{admin.userId.balance.PNG_Coin}</span>
        </div>
        <div className="flex justify-center items-center flex-col">
          <img
            className="w-20 sm:w-10 mt-10 sm:mt-0"
            src={`${process.env.PUBLIC_URL}/img/logo/Verified.png`}
            alt=""
          />
          <span className="mt-2 ">{levelForamter(admin.balance.AC).level}</span>
        </div>
        <div className="flex justify-center items-center flex-col">
          <img
            className="w-20 sm:w-10 mt-10 sm:mt-0"
            src={`${process.env.PUBLIC_URL}/img/logo/Chart.png`}
            alt=""
          />
          <span className="mt-2">{admin.sell.count}</span>
        </div>
        <div className="flex justify-center items-center flex-col">
          <img
            className="w-20 sm:w-10 mt-10 sm:mt-0"
            src={`${process.env.PUBLIC_URL}/img/logo/amar.png`}
            alt=""
          />
          <span className="mt-2">{admin.sell.price}</span>
        </div>
      </div>
      <div className="glass_card_stone max-h-[300px]  overflow-x-auto w-full md:w-9/12  mt-5  flex justify-between p-5">
        <div className="w-full text-center flex flex-col justify-between">
          <p className="text-gray-500">
            {" "}
            اخطار ها{" "}
            <span className="text-rose-700">({admin.warn.length})</span>
          </p>

          <div>
            {admin.warn.length === 0 ? (
              <div className="mt-5">
                <p className="text-rose-600 text-sm"> این کاربر اخطاری ندارد</p>
              </div>
            ) : (
              <>
                {admin.warn
                  .slice()
                  .reverse()
                  .map((n, i) => (
                    <>
                      <div
                        key={n._id}
                        className="w-full flex justify-between divide-current bg-stone-800/80 rounded-lg p-2 mt-5"
                      >
                        <p className="text-sm">
                          {i + 1}) {n.message}
                        </p>
                      </div>
                    </>
                  ))}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="w-full md:w-9/12 mt-5  flex justify-center md:justify-between flex-wrap">
        <div className="glass_card_stone h-[100px] w-full xl:w-[300px] p-2 flex justify-between ">
          <div className="w-full text-center flex flex-col justify-between">
            <p className="text-sm text-gray-500">دیسکورد</p>
            <p className="text-rose-500">{admin.media.discord}</p>
          </div>
          <img
            src={`${process.env.PUBLIC_URL}/img/logo/messenger.png`}
            alt=""
            className="w-20 h-20"
          />
        </div>
        <div className="glass_card_stone h-[100px]  w-full xl:w-[300px]  p-2 flex justify-between">
          <div className="w-full text-center flex flex-col justify-between">
            <p className="text-sm text-gray-500">اینستاگرام</p>
            <p className="text-rose-500">{admin.media.instagram}</p>
          </div>
          <img
            src={`${process.env.PUBLIC_URL}/img/logo/Instagram.png`}
            alt=""
            className="w-20 h-20"
          />
        </div>
      </div>
    </div>
  );
};

export default Profile;
