import { useState } from "react";
import axios from "../../config/axios";
import { useFormik } from "formik";
import Input from "./input";
import * as yup from "yup";
import { useNotification } from "../include/notif/hook";

export const Register = () => {
  let [btnStat, setBtnstat] = useState({ sending: false });
  const [showNotification, NotificationComponent] = useNotification();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    onSubmit: async (v) => {
      setBtnstat({ sending: true });
      axios
        .post("/auth/login", v)
        .then((res) => {
          if (res.data.message === "ok") {
            showNotification("با موفقیت وارد شدید", "موفق", 5, "ok");
            setTimeout(() => {
              window.location.href = res.data.link;
              setBtnstat({ sending: false });
            }, 2000);
          } else {
            showNotification(res.data.message, "خطا", 5, "err");
            console.error("Error: ", res.data.message);
            setBtnstat({ sending: false });
          }
        })
        .catch((err) => {
          if (err.response) {
            showNotification(err.response.data.message, "خطا", 3, "err");
          } else {
            showNotification(err.message, "خطا", 5, "err");
          }
          console.error("Error: ", err);
          setBtnstat({ sending: false });
        });
    },

    validationSchema: yup.object({
      email: yup
        .string()
        .required("ایمیل نمی تواند خالی باشد")
        .matches(/^[a-zA-Z0-9._%+-]+@gmail\.com$/, "فرمت مجاز gmail.com"),
      password: yup.string().required("پسورد نمی تواند خالی باشد"),
    }),
  });

  return (
    <div className="flex-1 mt-5 w-full flex justify-center">
      {NotificationComponent}
      <form
        onSubmit={formik.handleSubmit}
        className="glass_card w-11/12 sm:w-9/12 md:w-5/12 lg:w-3/12 p-5 h-auto flex flex-col items-center mt-[25vh]"
      >
        <p className="font-bold text-xl animate-bounce text-cyan-500">
          وارد شدن
        </p>
        <div className="flex flex-col items-center w-full mt-5">
          <Input
            name="ایمیل"
            id="email"
            type="text"
            place="png@gmail.com"
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          {formik.errors.email ? (
            <p className="text-red-500 text-sm w-10/12 mb-1">
              * {formik.errors.email}
            </p>
          ) : null}
          <Input
            name="پسورد"
            id="password"
            type="password"
            place="#Ahmad1234"
            onChange={formik.handleChange}
            value={formik.values.password}
          />
          {formik.errors.password ? (
            <p className="text-red-500 text-sm w-10/12 mb-1">
              * {formik.errors.password}
            </p>
          ) : null}
        </div>

        <button
          type="submit"
          className={
            btnStat.sending
              ? "btn btn-rose animate-bounce flex justify-center w-5/12 mt-5 "
              : "btn btn-rose flex justify-center w-5/12 mt-5"
          }
          disabled={btnStat.sending}
        >
          {btnStat.sending ? (
            <div className="w-6 h-6 border-b-2 border-r-2 rounded-full animate-spin"></div>
          ) : (
            "ورود"
          )}
        </button>
      </form>
    </div>
  );
};

export default Register;
