const Input = ({ name, id, type, onChange }) => {
  return (
    <input
      className="input w-full"
      type={type}
      name={id}
      id={id}
      placeholder={name}
      onChange={onChange}
    />
  );
};

export default Input;
