import { Navigate, Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "../../config/axios";
import Sidebar from "./sidebar/sidebar";
import Register from "./register-admin/register";
import Loading from "../include/loading/loading";

function UserMain() {
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    const loadUser = async () => {
      axios
        .get("/user/get-data")
        .then((res) => {
          if (res && res.data.message === "ok") {
            setUserData(res.data.data);
          } else {
            setUserData(false);
          }
        })
        .catch((err) => {
          if (err.response) {
            setUserData(false);
          } else {
            setUserData(false);
          }
        });
    };

    loadUser();
  }, []);

  if (userData === null) {
    return <Loading center={true} />;
  }
  console.log(userData);

  if (userData === false) {
    return <Navigate to={"/account/login"} />;
  }

  return (
    <div className="flex h-screen">
      {/* <Sidebar />  */}
      <div className="flex-1 p-4">
        <Routes>
          <Route path="admin/register" element={<Register />} />
        </Routes>
      </div>
    </div>
  );
}

export default UserMain;
