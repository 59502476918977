const Confirm = ({ text, title, onConfirm, show, setShow }) => {
  const handleCancel = () => {
    setShow(false);
  };

  return (
    <div
      className={` ${
        show ? "flex" : "hidden"
      } transition-all duration-300 w-full h-full fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[999] bg-black/60`}
    >
      <div
        className={`${
          show ? "top-1/2" : "to-[-50%]"
        } text-sm md:text-base transition-all duration-300 flex flex-col justify-center items-center w-[250px] md:w-[350px] glass_card_light absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[999] p-5 rounded-xl`}
      >
        <h1 className="mb-5 font-bold">{title}</h1>
        <p className="text-center">{text}</p>

        <div className="mt-5 flex justify-between w-full">
          <button
            className="btn-green btn w-20"
            onClick={() => {
              onConfirm();
              setShow(false);
            }}
          >
            YES
          </button>
          <button className="btn btn-red w-20" onClick={handleCancel}>
            NO
          </button>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
