import config from "../../../config/shared";

const levelFormatter = (ac) => {
  let acTable = config.AC;
  if (!acTable) {
    console.error("AC Table is not defined.");
    return null;
  }

  let currentLevel = 0;
  let nextLevelAC = acTable["1"];
  let remainingAC = 0;

  // مرتب کردن کلیدها برای اطمینان از ترتیب درست
  const sortedLevels = Object.keys(acTable).sort((a, b) => a - b);

  for (let level of sortedLevels) {
    if (ac >= acTable[level]) {
      currentLevel = parseInt(level);
    } else {
      nextLevelAC = acTable[level];
      remainingAC = nextLevelAC - ac;
      break;
    }
  }

  if (currentLevel === 0) {
    nextLevelAC = acTable["1"];
    remainingAC = nextLevelAC - ac;
  }


  if (!nextLevelAC) {
    nextLevelAC = Infinity;
    remainingAC = 0;
  }


  
  return {
    level: currentLevel,
    nextLevelAC: nextLevelAC,
    remainingAC: remainingAC,
    mande: nextLevelAC - remainingAC,
  };
};

export default levelFormatter;
