import { createRef, useEffect, useState } from "react";
import axios from "../../../config/axios";
import { useFormik } from "formik";
import Input from "./input";
import * as yup from "yup";
import { useNotification } from "../../include/notif/hook";
import { useNavigate } from "react-router-dom";

export const CreateLicense = () => {
  let navigate = useNavigate();
  let [btnStat, setBtnstat] = useState({ sending: false, text: "ارسال" });
  let [checkBox, setCheckBox] = useState({ ipLock: true, lifeTime: false });
  const [data, setData] = useState(null);
  const [showNotification, NotificationComponent] = useNotification();
  let btn = createRef();
  let inputLife = createRef();
  let inputIp = createRef();

  const fetchData = () => {
    axios
      .get("/admin/users")
      .then((res) => {
        if (res && res.data.message === "ok") {
          console.log(res.data.data);

          setData(res.data.data);
        } else {
          console.error(res.data.message);
        }
      })
      .catch((err) => console.error(err.response?.data.message || err.message));
  };

  useEffect(() => {
    function def() {
      setCheckBox({
        lifeTime: inputLife.current.checked,
        ipLock: inputIp.current.checked,
      });
    }
    def();
    fetchData();
  }, []);

  const formik = useFormik({
    initialValues: {
      user: "",
      server: "",
      expired: "",
      isIpLock: "",
      isLifeTime: "",
      productTag: "",
    },

    onSubmit: async (v) => {
      setBtnstat({ sending: true, text: "درحال ارسال" });
      axios
        .post("/admin/create-license", v)
        .then((res) => {
          if (res.data.message === "ok") {
            showNotification("لایسنس ساخته شد", "موفق", 5, "ok");
            setTimeout(() => {
              navigate("/admin/license/list");
              setBtnstat({ sending: false, text: "ارسال" });
            }, 2000);
          } else {
            showNotification(res.data.message, "error", 5, "err");
            setBtnstat({ sending: false, text: "ارسال" });
          }
        })
        .catch((e) => {
          console.log(e);
          showNotification(e.response.data.message, "خطا", 5, "err");
          setBtnstat({ sending: false, text: "ارسال" });
        });
    },

    validationSchema: yup.object({
      user: yup.string().required("یک کاربر را انتخاب کنید"),
      server: checkBox.ipLock
        ? yup.string().required("ای پی سرور نمیتواند خالی باشد")
        : yup.string(),
      expired: !checkBox.lifeTime
        ? yup.date().required("تاریخ نمیتواند خالی باشد")
        : yup.date(),
      productTag: yup
        .string()
        .required("نام و یا ایدی محصول نمیتواند خالی باشد"),
    }),
  });

  const hideManger = (e) => {
    setCheckBox({
      lifeTime: inputLife.current.checked,
      ipLock: inputIp.current.checked,
    });

    formik.setFieldValue(inputLife.current.name, inputLife.current.checked);
    formik.setFieldValue(inputIp.current.name, inputIp.current.checked);
  };

  return (
    <div className="flex-1 mt-5 w-full flex justify-center">
      {NotificationComponent}
      <form
        onSubmit={formik.handleSubmit}
        className="glass_card w-full sm:w-8/12 md:w-6/12 lg:w-4/12 p-5 h-auto flex flex-col items-center"
      >
        <p className="font-bold text-xl animate-bounce">ساخت لایسنس</p>

        <div className="flex flex-col items-center w-full mt-5">
          <div className="w-11/12 flex flex-col my-3">
            <select
              name="user"
              className="text-sm sm:text-base backdrop-blur-3xl bg-gradient-to-tr bg-stone-800/80 select-none outline-0 rounded-md border border-rose-900 mt-1 px-2 py-3 text-slate-300 shadow-md shadow-black my-3 w-full"
              value={formik.values.user}
              onChange={formik.handleChange}
            >
              <option value="" disabled>
                انتخاب کاربر
              </option>
              {data?.map((c) => (
                <>
                  <option key={c._id} value={c._id}>
                    {c.name} - {c.phone}
                  </option>
                </>
              ))}
            </select>
          </div>

          {formik.errors.user ? (
            <p className="text-red-500 text-sm w-10/12 mb-1">
              * {formik.errors.user}
            </p>
          ) : null}

          <Input
            name="تگ محصول"
            id="productTag"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.productTag}
          />
          {formik.errors.productTag ? (
            <p className="text-red-500 text-sm w-10/12 mb-1">
              * {formik.errors.productTag}
            </p>
          ) : null}

          {!checkBox.ipLock ? null : (
            <>
              <Input
                name="ای پی سرور"
                id="server"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.server}
              />
              {formik.errors.server ? (
                <p className="text-red-500 text-sm w-10/12 mb-1">
                  * {formik.errors.server}
                </p>
              ) : null}
            </>
          )}

          {!checkBox.lifeTime ? (
            <>
              <Input
                name="تاریخ انقضا"
                id="expired"
                type="date"
                onChange={formik.handleChange}
                value={formik.values.expired}
              />
              {formik.errors.expired ? (
                <p className="text-red-500 text-sm w-10/12 mb-1">
                  * {formik.errors.expired}
                </p>
              ) : null}
            </>
          ) : null}

          <div className="w-11/12 mt-5 flex flex-row">
            <div className="flex ml-5">
              <input
                ref={inputLife}
                className="ml-3"
                type="checkbox"
                name="isLifeTime"
                onChange={hideManger}
                checked={formik.values.isLifeTime}
              />
              <p>لایف تایم</p>
            </div>

            <div className="flex">
              <input
                ref={inputIp}
                className="ml-3"
                type="checkbox"
                name="isIpLock"
                onChange={hideManger}
                checked={formik.values.isIpLock}
              />
              <p>ای پی لاک</p>
            </div>
          </div>
        </div>

        <button
          ref={btn}
          type="submit"
          className={
            btnStat.sending
              ? "btn btn-rose animate-bounce flex justify-center w-5/12 mt-5 "
              : "btn btn-rose flex justify-center w-5/12 mt-5"
          }
          disabled={btnStat.sending}
        >
          {btnStat.sending ? (
            <div className="w-6 h-6 border-b-2 border-r-2 rounded-full animate-spin"></div>
          ) : (
            "ارسال"
          )}
        </button>
      </form>
    </div>
  );
};

export default CreateLicense;
