import { Navigate, Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "../../config/axios";
import LicenseList from "./license/licenses";
import CreateLicense from "./license/create";
import Sidebar from "./sidebar/sidebar";
import AdminList from "./admin-manager/list";
import Profile from "./admin-manager/profile";
import NotAccess from "../include/err/err";
import Requests from "./admin-manager/requests";
import Loading from "../include/loading/loading";
import Info from "./dashboard/info";
import UserList from "./user/list";
import Exchange from "./dashboard/exchange";
import TransferRequests from "./transfer/requests";
import TransferHistory from "./transfer/history";
import AdminContext from "../../context/adminContext";
import AdminLogs from "./control-pannel/admin-log";
import UserLogs from "./control-pannel/user-log";
import Manage from "./admin-manager/manage";
import Discord from "./connections/discord";
function AdminMain() {
  const [adminData, setAdminData] = useState(null);
  const [err, setErr] = useState({
    is: false,
    message: "",
    code: 0,
    link: "/",
  });
  useEffect(() => {
    const loadadmin = async () => {
      axios
        .get("/admin/get-data")
        .then((res) => {
          if (res && res.data.message === "ok") {
            setAdminData(res.data.data);
          } else {
            setErr({ is: true, message: res.data.message, code: 403 });
          }
        })
        .catch((err) => {
          if (err.response) {
            setErr({
              is: true,
              message: err.response.data.message,
              code: err.response.status,
              link: err.response.data.link,
            });
          } else {
            setErr({
              is: true,
              message: err.message,
              code: 403,
            });
          }
        });
    };

    loadadmin();
  }, []);

  if (err.is) {
    return <NotAccess message={err.message} code={err.code} link={err.link} />;
  }

  if (!adminData) {
    return <Loading center={true} />;
  }

  return (
    <AdminContext.Provider value={adminData}>
      <div className="flex h-screen">
        <Sidebar />
        <div className="flex-1 p-4">
          <Routes>
            <Route path="dashboard/info" element={<Info />} />
            <Route path="dashboard/exchange" element={<Exchange />} />
            <Route path="license/list" element={<LicenseList />} />
            <Route path="license/create" element={<CreateLicense />} />
            <Route path="admins/list" element={<AdminList />} />
            <Route path="admins/profile/:id" element={<Profile />} />
            <Route path="admins/manage/:id" element={<Manage />} />
            <Route path="admins/requests" element={<Requests />} />
            <Route path="users/view" element={<UserList />} />
            <Route path="transfer/requests" element={<TransferRequests />} />
            <Route path="transfer/history" element={<TransferHistory />} />
            <Route path="control/admin-logs" element={<AdminLogs />} />
            <Route path="control/user-logs" element={<UserLogs />} />
            <Route path="connection/discord" element={<Discord />} />
            <Route path="*" element={<Navigate to="dashboard/info" />} />
          </Routes>
        </div>
      </div>
    </AdminContext.Provider>
  );
}

export default AdminMain;
