import { useState } from "react";
import Notif from "./notifactions";

export const useNotification = () => {
  const [notification, setNotification] = useState({
    msg: "",
    title: "",
    time: 0,
    type: "",
    show: false,
  });

  const showNotification = (msg, title, time, type) => {
    setNotification({ msg, title, time, type, show: true });
    setTimeout(() => {
      setNotification({ ...notification, show: false });
    }, time * 1000);
  };

  const NotificationComponent = notification.show ? <Notif {...notification} /> : null;

  return [showNotification, NotificationComponent];
};
