import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./login";
import Register from "./register";
import NotAccess from "../include/err/err";
import Loading from "../include/loading/loading";
import axios from "../../config/axios";
import { useEffect, useState } from "react";
function AuthMain() {
  const [login, setlogin] = useState(null);

  useEffect(() => {
    const loaduser = async () => {
      axios
        .get("/auth/is-login")
        .then((res) => {
          if (res && res.data.message === "ok") {
            setlogin("yes");
          } else {
            setlogin("no");
          }
        })
        .catch((err) => {
          if (err.response) {
            setlogin("no");
          } else {
            setlogin("no");
          }
        });
    };

    loaduser();
  }, []);

  if (!login) {
    return <Loading center={true} />;
  }

  // if (login === "yes") {
  //   return <Navigate to={"/user/dashboard"} />;
  // }

  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      {/* <Route path="login" element={<CreateLicense />} /> */}
    </Routes>
  );
}
export default AuthMain;
