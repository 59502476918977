import axios from "../../../config/axios";
import { useEffect, useRef, useState } from "react";
import { FaArrowAltCircleDown, FaArrowAltCircleUp } from "react-icons/fa";
import { useNotification } from "../../include/notif/hook";
import Loading from "../../include/loading/loading";
import config from "../../../config/shared";
import ERR from "../../include/err/message";
import { useConfirm } from "../../include/confrim/hook";

const Requests = () => {
  const [admins, setAdmins] = useState([]);
  const [showConfirm, confirmComponent] = useConfirm();
  const [err, setErr] = useState();
  const selectWork = useRef();
  const selectdev = useRef();
  const selectdis = useRef();
  const selectMod = useRef();
  const selectTread = useRef();
  const selectContent = useRef();
  const [showNotification, NotificationComponent] = useNotification();
  useEffect(() => {
    const loadAdmins = async () => {
      axios
        .get("/admin/admins-queue")
        .then((res) => {
          if (res && res.data.message === "ok") {
            const adminsWithSlid = res.data.data.map((admin) => ({
              ...admin,
              slid: 1,
              developer: [],
              content_creator: [],
              designer: [],
              modeler: [],
              trader: [],
            }));
            setAdmins(adminsWithSlid);
          } else {
            setErr(res.data.message);
          }
        })
        .catch((err) => {
          if (err.response) {
            setErr(err.response.data.message);
          } else {
            setErr(err.message);
          }
        });
    };

    loadAdmins();
  }, []);
  function post(id, type, role, isRemove) {
    axios
      .put("/admin/update-requests", { type, id, role })
      .then((res) => {
        if (res.data.message === "ok") {
          showNotification("عملیات با موفقیت انجام شد", "موفق", 6, "ok");
          if (isRemove) {
            let a = admins.filter((a) => a._id !== id);
            if (a.length > 0) {
              setAdmins(a);
            } else {
              setErr("ادمینی در صف اهراز هویت نیست");
              setAdmins([]);
            }
          }
        } else {
          showNotification(res.data.message, "خطا", 6, "err");
        }
      })
      .catch((err) => {
        showNotification(err.message, "خطا", 6, "err");
      });
  }

  const toggleAdminDetails = (id) => {
    setAdmins((prevAdmins) =>
      prevAdmins.map((admin) =>
        admin._id === id ? { ...admin, isOpen: !admin.isOpen } : admin
      )
    );
  };

  const verify = (id) => {
    admins.map((a) => {
      if (a._id === id) {
        const data = {
          developer: a.developer,
          content_creator: a.content_creator,
          designer: a.designer,
          modeler: a.modeler,
          trader: a.trader,
        };

        post(id, "verify", data, true);
      }
    });
  };

  const reject = (id) => {
    showConfirm("آیا مطمعن هستید ؟", "تایید ریجکت", () => {
      post(id, "reject", null, true);
    });
  };
  const addWork = (id) => {
    const w = selectWork.current.value;
    post(id, "work", w, false);
  };
  const addDev = (id) => {
    const selectedDev = selectdev.current.value;

    setAdmins((prevAdmins) =>
      prevAdmins.map((a) => {
        if (
          a._id === id &&
          !a.developer.includes(selectedDev) &&
          a.developer !== ""
        ) {
          showNotification(
            `توانایی ${selectedDev}  به دسته دولوپر اضافه این فرد اکنون ${
              a.developer.length + 1
            } توانایی دارد`,
            "موفق",
            6,
            "ok"
          );
          return {
            ...a,
            developer: [...a.developer, selectedDev],
          };
        } else {
          showNotification(
            "توانایی اضافه نشد لطفا یه توانایی انتخاب کنید",
            "خطا",
            6,
            "err"
          );
        }
        return a;
      })
    );
  };

  const addDis = (id) => {
    const selectedDis = selectdis.current.value;

    setAdmins((prevAdmins) =>
      prevAdmins.map((a) => {
        if (
          a._id === id &&
          !a.designer.includes(selectedDis) &&
          a.designer !== ""
        ) {
          showNotification(
            `توانایی ${selectedDis}  به دسته دیزاینر اضافه این فرد اکنون ${
              a.designer.length + 1
            } توانایی دارد`,
            "موفق",
            6,
            "ok"
          );
          return {
            ...a,
            designer: [...a.designer, selectedDis],
          };
        } else {
          showNotification(
            "توانایی اضافه نشد لطفا یه توانایی انتخاب کنید",
            "خطا",
            6,
            "err"
          );
        }
        return a;
      })
    );
  };

  const addContent = (id) => {
    const selectedContent = selectContent.current.value;
    setAdmins((prevAdmins) =>
      prevAdmins.map((a) => {
        if (
          a._id === id &&
          !a.content_creator.includes(selectedContent) &&
          a.content_creator !== ""
        ) {
          showNotification(
            `توانایی ${selectedContent}  به دسته تولید محتوا اضافه این فرد اکنون ${
              a.content_creator.length + 1
            } توانایی دارد`,
            "موفق",
            6,
            "ok"
          );
          return {
            ...a,
            content_creator: [...a.content_creator, selectedContent],
          };
        } else {
          showNotification(
            "توانایی اضافه نشد لطفا یه توانایی انتخاب کنید",
            "خطا",
            6,
            "err"
          );
        }
        return a;
      })
    );
  };

  const addMod = (id) => {
    const selectedMod = selectMod.current.value;
    setAdmins((prevAdmins) =>
      prevAdmins.map((a) => {
        if (
          a._id === id &&
          !a.modeler.includes(selectedMod) &&
          a.modeler !== ""
        ) {
          showNotification(
            `توانایی ${selectedMod}  به دسته مدلر اضافه این فرد اکنون ${
              a.designer.modeler + 1
            } توانایی دارد`,
            "موفق",
            6,
            "ok"
          );
          return {
            ...a,
            modeler: [...a.modeler, selectedMod],
          };
        } else {
          showNotification(
            "توانایی اضافه نشد لطفا یه توانایی انتخاب کنید",
            "خطا",
            6,
            "err"
          );
        }
        return a;
      })
    );
  };

  const addTrade = (id) => {
    const selectedTrade = selectTread.current.value;
    setAdmins((prevAdmins) =>
      prevAdmins.map((a) => {
        if (
          a._id === id &&
          !a.trader.includes(selectedTrade) &&
          a.modeler !== ""
        ) {
          showNotification(
            `توانایی ${selectedTrade}  به دسته ترید اضافه این فرد اکنون ${
              a.designer.trader + 1
            } توانایی دارد`,
            "موفق",
            6,
            "ok"
          );
          return {
            ...a,
            trader: [...a.trader, selectedTrade],
          };
        } else {
          showNotification(
            "توانایی اضافه نشد لطفا یه توانایی انتخاب کنید",
            "خطا",
            6,
            "err"
          );
        }
        return a;
      })
    );
  };
  const nextSlid = (id) => {
    setAdmins((prevAdmins) =>
      prevAdmins.map((admin) =>
        admin._id === id ? { ...admin, slid: admin.slid + 1 } : admin
      )
    );
  };

  if (err) {
    return <ERR msg={err} />;
  }

  return (
    <div className="flex flex-col items-center w-full">
      {NotificationComponent}
      {confirmComponent}
      <div className="w-full md:w-8/12 xl:w-7/12 glass_card_stone border-2 border-dashed border-rose-700 p-1 md:p-3 mb-10">
        <p className="text-base md:text-xl text-red-500">نکات مهم :</p>
        <div className="mt-1 mx-2 text-xs md:text-base py-2">
          <li className="my-4 md:my-1">
            پس از تایید هویت به صورت خودکار رول PNG-Team داده میشود و بعد از رد
            کردن کل اطلاعات پاک خواهد شد
          </li>
          <li className="my-4 md:my-1">
            در وارد کردن نقش ها دقت کنید ، در غیر اینصورت ممکن است وارن دریافت
            کنید
          </li>
          <li className="my-4 md:my-1">
            از قدیمی ترین رکوست شروع به تایید کنید و درغیر اینصورت تایید انجام
            نمیشه
          </li>
        </div>
      </div>
      {admins.length == 0 ? <Loading center={false} /> : null}
      {admins.map((a) => {
        return (
          <div
            className="w-full md:w-[500px] glass_card_stone rounded-lg pt-5 px-5 flex flex-col justify-center items-center my-3 transition-all duration-300 hover:ring-2 ring-rose-700"
            key={a._id}
          >
            <div
              className={`transition-all duration-500 ${
                a.isOpen ? "hidden" : "flex"
              } justify-between w-full`}
            >
              <div className="text-sm text-rose-500 md:text-base">
                {a.firstname} {a.lastname}
              </div>
              <div className="text-xs text-rose-500 md:text-base">
                {a.createdAt.toString().split("T")[0]}
              </div>
            </div>
            <div
              className={`transition-all duration-500 ${
                a.isOpen ? "block" : "hidden"
              } w-full`}
            >
              <div className="flex flex-col items-center md:flex-row md:justify-between w-full">
                <div className="text-sm md:text-base flex flex-col justify-center w-auto md:w-40">
                  <p className="text-gray-400 text-sm self-center md:self-start my-2">
                    نام و نام خانوادگی :
                  </p>
                  <p className="text-rose-700 self-center md:slef-end">
                    {a.firstname} {a.lastname}
                  </p>
                </div>
                <div className="mt-5 md:mt-0 text-sm md:text-base flex flex-col justify-center w-auto md:w-40">
                  <p className="text-gray-400 text-sm self-center md:self-start my-2">
                    شماره :
                  </p>
                  <p className="text-rose-700 self-center md:slef-end">
                    {a.userId.phone}
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center md:flex-row md:justify-between w-full mt-5">
                <div className="text-sm md:text-base flex flex-col justify-center w-auto md:w-40">
                  <p className="text-gray-400 text-sm self-center md:self-start my-2">
                    ایمیل :
                  </p>
                  <p className="text-rose-700 self-center md:slef-end">
                    {a.userId.email.split("@", 1)}
                  </p>
                </div>
                <div className="mt-5 md:mt-0 text-sm md:text-base flex flex-col justify-center w-auto md:w-40">
                  <p className="text-gray-400 text-sm self-center md:self-start my-2">
                    تاریخ تولد :
                  </p>
                  <p className="text-rose-700 self-center md:slef-end">
                    {a.birthdate}
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center md:flex-row md:justify-between w-full mt-5">
                <div className="text-sm md:text-base flex flex-col justify-center w-auto md:w-40">
                  <p className="text-gray-400 text-sm self-center md:self-start my-2">
                    شماره کارت :
                  </p>
                  <p className="text-rose-700 self-center md:slef-end">
                    {a.card.number}
                  </p>
                </div>
                <div className="mt-5 md:mt-0 text-sm md:text-base flex flex-col justify-center w-auto md:w-40">
                  <p className="text-gray-400 text-sm self-center md:self-start my-2">
                    نام صاحب کارت :
                  </p>
                  <p className="text-rose-700 self-center md:slef-end">
                    {a.card.name}
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center md:flex-row md:justify-between w-full mt-5">
                <div className="text-sm md:text-base flex flex-col justify-center w-auto md:w-40">
                  <p className="text-gray-400 text-sm self-center md:self-start my-2">
                    ایدی دیسکورد :
                  </p>
                  <p className="text-rose-700 self-center md:slef-end">
                    {a.media.discord}
                  </p>
                </div>
                <div className="mt-5 md:mt-0 text-sm md:text-base flex flex-col justify-center w-auto md:w-40">
                  <p className="text-gray-400 text-sm self-center md:self-start my-2">
                    ایدی اینستاگرام :
                  </p>
                  <p className="text-rose-700 self-center md:slef-end">
                    {a.media.instagram}
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center justify-between w-full mt-5">
                <div className="mt-5 md:mt-0 text-sm md:text-base flex flex-col items-center justify-center w-auto">
                  <p className="text-gray-400 text-sm my-2 ">ادرس:</p>
                  <p className="text-rose-700 text-center w-full">
                    {a.address}
                  </p>
                </div>
              </div>
              <div
                className={`${
                  a.slid === 1 ? "flex" : "hidden"
                } mt-10 w-full flex-col justify-center items-center text-sm`}
              >
                <select
                  className="w-full input"
                  ref={selectWork}
                  key={`work-${a._id}`}
                >
                  <option value=""> نقش کاری ادمین</option>
                  {config.role.work.map((r) =>
                    r.canAdd ? (
                      <option key={r.number} value={r.short}>
                        {r.short} | {r.name_fa}
                      </option>
                    ) : null
                  )}
                </select>

                <div className="flex w-full">
                  <button
                    className="text-sm btn btn-rose flex-1 ml-1"
                    onClick={() => addWork(a._id)}
                  >
                    افزودن
                  </button>
                  <button
                    className="text-sm btn btn-rose flex-1"
                    onClick={() => nextSlid(a._id)}
                  >
                    بعدی
                  </button>
                </div>
              </div>
              <div
                className={`${
                  a.slid === 2 ? "flex" : "hidden"
                } mt-10 w-full flex-col justify-center items-center text-sm`}
              >
                <select
                  className="w-full input"
                  ref={selectdev}
                  key={`dev-${a._id}`}
                >
                  <option value="">توانایی دولوپری (درصورت نیاز) </option>
                  {config.ability.developer.map((r, i) => (
                    <option key={i} value={r}>
                      {r}
                    </option>
                  ))}
                </select>

                <div className="flex w-full">
                  <button
                    className="text-sm btn btn-rose flex-1 ml-1"
                    onClick={() => addDev(a._id)}
                  >
                    افزودن
                  </button>
                  <button
                    className="text-sm btn btn-rose flex-1"
                    onClick={() => nextSlid(a._id)}
                  >
                    بعدی
                  </button>
                </div>
              </div>
              <div
                className={`${
                  a.slid === 3 ? "flex" : "hidden"
                } mt-10 w-full flex-col justify-center items-center text-sm`}
              >
                <select
                  className="w-full input"
                  ref={selectdis}
                  key={`dis-${a._id}`}
                >
                  <option value="">توانایی دیزاینری (درصورت نیاز) </option>
                  {config.ability.designer.map((r, i) => (
                    <option key={i} value={r}>
                      {r}
                    </option>
                  ))}
                </select>

                <div className="flex w-full">
                  <button
                    className="text-sm btn btn-rose flex-1 ml-1"
                    onClick={() => addDis(a._id)}
                  >
                    افزودن
                  </button>
                  <button
                    className="text-sm btn btn-rose flex-1"
                    onClick={() => nextSlid(a._id)}
                  >
                    بعدی
                  </button>
                </div>
              </div>
              <div
                className={`${
                  a.slid === 4 ? "flex" : "hidden"
                } mt-10 w-full flex-col justify-center items-center text-sm`}
              >
                <select
                  className="w-full input"
                  ref={selectMod}
                  key={`mod-${a._id}`}
                >
                  <option value="">توانایی مدلری (درصورت نیاز) </option>
                  {config.ability.modeler.map((r, i) => (
                    <option key={i} value={r}>
                      {r}
                    </option>
                  ))}
                </select>

                <div className="flex w-full">
                  <button
                    className="text-sm btn btn-rose flex-1 ml-1"
                    onClick={() => addMod(a._id)}
                  >
                    افزودن
                  </button>
                  <button
                    className="text-sm btn btn-rose flex-1"
                    onClick={() => nextSlid(a._id)}
                  >
                    بعدی
                  </button>
                </div>
              </div>
              <div
                className={`${
                  a.slid === 5 ? "flex" : "hidden"
                } mt-10 w-full flex-col justify-center items-center text-sm`}
              >
                <select
                  className="w-full input"
                  ref={selectContent}
                  key={`content-${a._id}`}
                >
                  <option value="">توانایی تولید محتوا (درصورت نیاز) </option>
                  {config.ability.content_creator.map((r, i) => (
                    <option key={i} value={r}>
                      {r}
                    </option>
                  ))}
                </select>

                <div className="flex w-full">
                  <button
                    className="text-sm btn btn-rose flex-1 ml-1"
                    onClick={() => addContent(a._id)}
                  >
                    افزودن
                  </button>
                  <button
                    className="text-sm btn btn-rose flex-1"
                    onClick={() => nextSlid(a._id)}
                  >
                    بعدی
                  </button>
                </div>
              </div>
              <div
                className={`${
                  a.slid === 6 ? "flex" : "hidden"
                } mt-10 w-full flex-col justify-center items-center text-sm`}
              >
                <select
                  className="w-full input"
                  ref={selectTread}
                  key={`trade-${a._id}`}
                >
                  <option value="">توانایی ترید (درصورت نیاز) </option>
                  {config.ability.trader.map((r, i) => (
                    <option key={i} value={r}>
                      {r}
                    </option>
                  ))}
                </select>

                <div className="flex w-full">
                  <button
                    className="text-sm btn btn-rose flex-1 ml-1"
                    onClick={() => addTrade(a._id)}
                  >
                    افزودن
                  </button>
                  <button
                    className="text-sm btn btn-rose flex-1"
                    onClick={() => nextSlid(a._id)}
                  >
                    بعدی
                  </button>
                </div>
              </div>
              <div className="mt-16 w-full flex justify-around text-sm">
                <button
                  disabled={a.slid >= 6 ? false : true}
                  className="btn btn-green"
                  onClick={() => verify(a._id)}
                >
                  تایید هویت
                </button>
                <button className="btn btn-red" onClick={() => reject(a._id)}>
                  رد کردن
                </button>
              </div>
            </div>

            {a.isOpen ? (
              <FaArrowAltCircleUp
                onClick={() => toggleAdminDetails(a._id)}
                className="text-stone-600 animate-bounce mt-2 mb-1 cursor-pointer"
              />
            ) : (
              <FaArrowAltCircleDown
                onClick={() => toggleAdminDetails(a._id)}
                className="text-stone-600 animate-bounce mt-2 mb-1 cursor-pointer"
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Requests;
