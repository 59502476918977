import React, { useEffect, useState } from "react";
import { useContext } from "react";
import AdminContext from "../../../context/adminContext";
import {
  FcBusinessman,
  FcShop,
  FcPaid,
  FcCommandLine,
  FcFaq,
  FcHome,
  FcOnlineSupport,
  FcSignature,
} from "react-icons/fc";
import { FaWifi } from "react-icons/fa";
import { CiGrid41, CiUnread } from "react-icons/ci";
import Side from "./side";

const Sidebar = () => {
  const adminContext = useContext(AdminContext);
  const [isOpen, setIsOpen] = useState(true);
  const [admin, setAdmin] = useState(null);
  const [work, setWork] = useState(null);
  useEffect(() => {
    function setRank() {
      setAdmin(adminContext.role.admin);
      setWork(adminContext.role.work);
    }

    setRank();
  }, [adminContext.role.admin, adminContext.role.work]);

  const links = [
    {
      name: "داشبورد",
      check: "admin",
      rank: ["PNG-Team", "PNG-Core"],
      icon: FcHome,
      id: "dashboard",
      subLinks: [
        {
          name: "اطلاعات",
          id: "info",
          check: "admin",
          rank: ["PNG-Team", "PNG-Core"],
        },
        {
          name: "صرافی من",
          id: "exchange",
          check: "admin",
          rank: ["PNG-Team", "PNG-Core"],
        },
        // { name: "تنظیمات", id: "setting"  , check : "admin" , rank : ["PNG-Team" , "PNG-Core"]},
        // { name: "تغییر اطلاعات", id: "edit"  , check : "admin" , rank : ["PNG-Team" , "PNG-Core"]},
        // { name: "پشتیبان من", id: "support"  , check : "admin" , rank : ["PNG-Team" , "PNG-Core"]},
      ],
    },
    // {
    //   name: "مدیریت سفارشات",
    //   check: "work",
    //   rank: [],
    //   icon: FcShop,
    //   id: "orders",
    //   subLinks: [
    //     {
    //       name: "سفارشات باز",
    //       id: "open",
    //       check: "admin",
    //       rank: ["PNG-Team", "PNG-Core"],
    //     },
    //     {
    //       name: "تاریخچه",
    //       id: "history",
    //       check: "admin",
    //       rank: ["PNG-Team", "PNG-Core"],
    //     },
    //   ],
    // },
    // {
    //   name: "مدیریت محصولات",
    //   check: "admin",
    //   rank: [],
    //   icon: FcPaid,
    //   id: "products",
    //   subLinks: [
    //     {
    //       name: "مشاهده محصولات",
    //       id: "view",
    //       check: "admin",
    //       rank: ["PNG-Team", "PNG-Core"],
    //     },
    //     {
    //       name: "ایجاد محصول",
    //       id: "create",
    //       check: "admin",
    //       rank: ["PNG-Team", "PNG-Core"],
    //     },
    //   ],
    // },
    {
      name: "مدیریت کاربران",
      check: "admin",
      rank: ["PNG-Team", "PNG-Core"],
      icon: FcBusinessman,
      id: "users",
      subLinks: [
        {
          name: "لیست کاربران",
          id: "view",
          check: "admin",
          rank: ["PNG-Team", "PNG-Core"],
        },
        // { name: "امتیازات", id: "create" },
      ],
    },
    {
      name: "مدیریت ادامین",
      check: "admin",
      rank: ["PNG-Team", "PNG-Core"],
      icon: FcOnlineSupport,
      id: "admins",
      subLinks: [
        {
          name: "لیست ادمین ها",
          id: "list",
          check: "admin",
          rank: ["PNG-Team", "PNG-Core"],
        },
        {
          name: "درخواست ها",
          id: "requests",
          check: "admin",
          rank: ["PNG-Core"],
        },
      ],
    },

    {
      name: "مدیریت ارتباطات",
      check: "admin",
      rank: ["PNG-Core"],
      icon: FcFaq,
      id: "connection",
      subLinks: [
        {
          name: "دیسکورد",
          id: "discord",
          check: "admin",
          rank: ["PNG-Core"],
        },
        // {
        //   name: "تیکت",
        //   id: "ticket",
        //   check: "admin",
        //   rank: ["PNG-Team", "PNG-Core"],
        // },
      ],
    },
    {
      name: "لایسنس",
      check: "admin",
      rank: ["PNG-Core"],
      icon: FcSignature,
      id: "license",
      subLinks: [
        {
          name: "مشاهده",
          id: "list",
          check: "admin",
          rank: ["PNG-Core"],
        },
        {
          name: "ساخت",
          id: "create",
          check: "admin",
          rank: ["PNG-Core"],
        },
      ],
    },

    {
      name: "حسابداری",
      check: "work",
      rank: ["CFO"],
      icon: FcSignature,
      id: "transfer",
      subLinks: [
        {
          name: "درخواست ها",
          id: "requests",
          check: "work",
          rank: ["CFO"],
        },
        {
          name: "همه تراکنش ها",
          id: "history",
          check: "work",
          rank: ["CFO"],
        },
      ],
    },
    {
      name: "کنترل پنل",
      check: "admin",
      rank: [],
      icon: FcCommandLine,
      id: "control",
      subLinks: [
        // {
        //   name: "مدیریت نظرات",
        //   id: "comment",
        //   check: "admin",
        //   rank: [],
        // },
        {
          name: "لاگ کابران",
          id: "user-logs",
          check: "admin",
          rank: [],
        },
        {
          name: "لاگ ادمین ها",
          id: "admin-logs",
          check: "admin",
          rank: [],
        },
        // {
        //   name: "تاییدیه نهایی",
        //   id: "verify",
        //   check: "admin",
        //   rank: [],
        // },
        // {
        //   name: "آمار کلی",
        //   id: "statistics",
        //   check: "admin",
        //   rank: [],
        // },
      ],
    },
  ];

  return (
    <>
      <CiGrid41
        className="cursor-pointer fixed top-3 right-3 size-8 text-blue-600 z-10"
        onClick={() => setIsOpen(!isOpen)}
      />
      <div
        className={`${
          isOpen ? "right-[-100%]" : "right-0"
        } z-10 overflow-x-auto transition-all flex duration-300  fixed top-0 right-0 bg-slate-900/50 backdrop-blur-3xl w-[250px] h-full text-slate-200 flex-col items-center py-3 px-5 font-[ir]`}
      >
        <div className="flex-1 w-full mt-2">
          <div className="flex w-full justify-between">
            <h1>PNG</h1>
            <CiUnread
              className="size-6 cursor-pointer  text-red-600"
              onClick={() => setIsOpen(!isOpen)}
            />
          </div>

          <div className="w-full mt-16">
            {links
              .filter((link) => {
                if (admin === "Founder") {
                  return link;
                }
                if (link.check === "admin") {
                  return link.rank.includes(admin);
                } else if (link.check === "work") {
                  return link.rank.includes(work);
                }
                return true;
              })
              .map((link, index) => (
                <Side
                  key={index}
                  name={link.name}
                  Icons={link.icon}
                  id={link.id}
                  subLinks={link.subLinks.filter((sub) => {
                    if (admin === "Founder") {
                      return sub;
                    }
                    if (sub.check === "admin") {
                      return sub.rank.includes(admin);
                    } else if (sub.check === "work") {
                      return sub.rank.includes(work);
                    }
                    return true;
                  })}
                />
              ))}
          </div>
        </div>
        <div className="flex flex-row w-full justify-around bg-slate-950 p-2 rounded-xl items-center animate-pulse cursor-pointer">
          <img
            className="rounded-full w-12 h-12"
            src={`${process.env.PUBLIC_URL}${adminContext.userId.profile}`}
            alt="Profile"
          />
          <div className="flex flex-col justify-center">
            <h1 className="text-base font-bold my-2">
              {adminContext.firstname}
            </h1>
            <div className="flex w-full items-center">
              <FaWifi className="text-green-600 size-4 rounded-full ml-3 " />
              <p className="text-xs">{adminContext.role.admin}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
