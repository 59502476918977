import { Link } from "react-router-dom";

const NotAccess = ({ message, code, link, name }) => {
  return (
    <>
      <div className="flex-col flex items-center justify-center min-h-screen">
        <div className="glass_card_stone w-[400px] animate-bounce border border-dashed border-rose-700 transition-transform duration-500 hover:scale-10 p-8 max-w-sm mx-auto shadow-lg">
          <div className="flex justify-center items-center mb-4">
            <img
              className="w-16 h-16"
              src={`${process.env.PUBLIC_URL}/img/logo/err.png`}
            />
          </div>
          <h1 className="text-2xl font-semibold text-gray-100 mb-2 text-center">
            {code}
          </h1>
          <p className="text-gray-300 text-center">{message}</p>
        </div>

        <Link className="mt-10 text-blue-500 underline" to={link}>
          بازگشت
        </Link>
      </div>
    </>
  );
};

export default NotAccess;
