import { useEffect, useState } from "react";
import axios from "../../../config/axios";
import { useNotification } from "../../include/notif/hook";
import Loading from "../../include/loading/loading";
import ERR from "../../include/err/message";
import levelForamter from "../../include/textForamt/level";
import { useNavigate } from "react-router-dom";

export const AdminList = () => {
  const [admins, setAdmins] = useState([]);
  const [err, setErr] = useState(null);
  const [showNotification, NotificationComponent] = useNotification();
  let navigate = useNavigate();
  useEffect(() => {
    async function load() {
      axios
        .get("/admin/admins")
        .then((res) => {
          if (res && res.data.message === "ok") {
            setAdmins(res.data.data);
          } else {
            setErr(res.data.message);
          }
        })
        .catch((err) => {
          if (err.response) {
            setErr(err.response.data.message);
          } else {
            setErr(err.message);
          }
        });
    }

    load();
  }, []);

  const loadHandle = (id) => {
    navigate("/admin/admins/profile/" + id);
  };

  const copyHandel = (text) => {
    navigator.clipboard.writeText(text);
    showNotification("متن مورد نظر کپی شد", "موفق", 5, "ok");
  };

  if (err) {
    return <ERR msg={err} />;
  }

  if (admins.length === 0) {
    return <Loading center={true} />;
  }

  return (
    <div className="flex flex-col items-center mt-5 w-full p-3">
      {NotificationComponent}

      <div className="w-full flex justify-center flex-wrap">
        {admins.map((admin, index) =>
          admin.userId ? (
            <>
              <div
                onClick={() => {
                  loadHandle(admin._id);
                }}
                key={index}
                className="cursor-pointer m-3 flex flex-col px-3 py-4 border border-dashed w-[200px] border-rose-700 shadow-md shadow-rose-950 rounded-2xl backdrop-blur-sm bg-gradient-to-l from-[#eb0a3f17] to-[#00000000] transition-transform duration-500 hover:scale-105"
              >
                <div className="flex justify-between w-full">
                  <div
                    className={`w-4 h-4 ${
                      admin.role.verify
                        ? admin.active
                          ? "border-green-500"
                          : "border-red-500"
                        : "border-orange-500"
                    } border-2 rounded-full flex justify-center items-center`}
                  >
                    <div
                      className={`animate-ping w-2 h-2 ${
                        admin.role.verify
                          ? admin.active
                            ? "bg-green-500"
                            : "bg-red-500"
                          : "bg-orange-500"
                      }  rounded-full`}
                    ></div>
                  </div>
                  <div className="text-sm rtl text-gray-500">
                    level :{" "}
                    <span className="text-yellow-300 font-bold text-base">
                      {levelForamter(admin.balance.AC).level}
                    </span>
                  </div>
                </div>
                <div className="w-full flex justify-center mt-3 mb-4">
                  {console.log(admin.userId.profile)}
                  <img
                    className="rounded-full w-[90px] h-[90px]"
                    src={`${admin.userId.profile}`}
                    alt="Profile"
                  />
                </div>
                <div className="w-full flex items-center flex-col">
                  <p className="mb-3 font-bold">
                    {admin.firstname + " " + admin.lastname}
                  </p>
                  <p className="text-rose-700 text-sm">{admin.role.admin}</p>
                  <p className="text-[0.7em] text-stone-600">
                    {admin.role.work}
                  </p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                onClick={() => {
                  copyHandel(admin._id);
                }}
                key={index}
                className="cursor-pointer m-3 flex flex-col px-3 py-4 border border-dashed w-[200px] border-rose-700 shadow-md shadow-rose-950 rounded-2xl backdrop-blur-sm bg-gradient-to-l from-[#eb0a3f17] to-[#00000000] transition-transform duration-500 hover:scale-105"
              >
                <div className="w-full flex justify-center mt-3 mb-4">
                  <img
                    className="rounded-full w-[90px] h-[90px]"
                    src={`${process.env.PUBLIC_URL}/img/logo/n-a.png`}
                    alt="Profile"
                  />
                </div>
                <div className="w-full flex items-center flex-col">
                  <p className="mb-3 font-bold">user found</p>
                  <p className="text-rose-700 text-sm">{"please check"}</p>
                  <p className="text-[0.7em] text-stone-600">{admin._id}</p>
                </div>
              </div>
            </>
          )
        )}
      </div>
    </div>
  );
};

export default AdminList;
