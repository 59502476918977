const ERR = ({msg}) => {
  return (
    <div className="flex w-full justify-center">
      <div className="glass_card_rose p-5 ">
        <p className="text-red-500 animate-pulse">{msg}</p>
      </div>
    </div>
  );
};

export default ERR;
