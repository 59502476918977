import { useEffect, useState } from "react";
import axios from "../../../config/axios";
import { useNotification } from "../../include/notif/hook";
import Loading from "../../include/loading/loading";
import ERR from "../../include/err/message";
import levelForamter from "../../include/textForamt/level";

export const TransferHistory = () => {
  const [requests, setRequests] = useState([]);
  const [err, setErr] = useState(null);
  const [showNotification, NotificationComponent] = useNotification();
  useEffect(() => {
    async function load() {
      axios
        .get("/admin/transfer-requests")
        .then((res) => {
          if (res && res.data.message === "ok") {
            setRequests(res.data.data);
          } else {
            setErr(res.data.message);
          }
        })
        .catch((err) => {
          if (err.response) {
            setErr(err.response.data.message);
          } else {
            setErr(err.message);
          }
        });
    }

    load();
  }, []);

  const copyHandel = (text) => {
    navigator.clipboard.writeText(text);
    showNotification("متن مورد نظر کپی شد", "موفق", 5, "ok");
  };

  if (err) {
    return <ERR  msg={err}/>;
  }

  if (requests.length === 0) {
    return <Loading center={true} />;
  }

  return (
    <div className="flex flex-col items-center mt-5 w-full p-3">
      {NotificationComponent}
      <div className="w-full flex justify-center "></div>
    </div>
  );
};

export default TransferHistory;
