const config = {
    platform: { instagram: 'https://www.instagram.com/png_community', telegram: "https://t.me/png_community", youtube: "https://www.youtube.com/@PNG_Community", discord: "https://discord.gg/r9WAbC6HBK" },
    maxWarn: 5,
    ability: {
        developer: [
            "front-end",
            "back-end",
            "full-stack",
            "Bot",
            "android",
            "IOS",
            "Game",
            "windows",
            "Data Science",
            "AI"
        ],
        content_creator: ["youtube", "aparat", "instagram", "telegram"],
        designer: [
            "Graphics Editor",
            "UI / UX Designer ",
            "Video Editor",
            "Motion graphics"
        ],
        modeler: ["3D Modeler", "2D modeler", "Game Modeler"],
        trader: ["Forex", "Crypto"]
    },
    role: {
        admin: ["PNG-Team", "PNG-Core", "Founder"],
        work: [
            {
                number: 1,
                short: "CEO",
                name_en: "Chief Executive Officer",
                name_fa: "مدیر عامل",
                canAdd: false,
            },
            {
                number: 2,
                short: "CAO",
                name_en: "Chief Administrative Officer",
                name_fa: "مدیر اداری",
                canAdd: false,
            },
            {
                number: 3,
                short: "CFO",
                name_en: "Chief Financial Officer",
                name_fa: "مدیر مالی",
                canAdd: false,
            },
            {
                number: 4,
                short: "CCO",
                name_en: "Chief Communications Officer",
                name_fa: "مدیر ارتباطات",
                canAdd: true,
            },
            {
                number: 5,
                short: "CLO",
                name_en: "Chief Legal Officer",
                name_fa: "مدیر حقوقی",
                canAdd: true,
            },
            {
                number: 6,
                short: "RCS",
                name_en: "Responsible for Customer Service",
                name_fa: "مسئول خدمات مشتری",
                canAdd: true,
            },
            {
                number: 7,
                short: "SMM",
                name_en: "Social Media Manager",
                name_fa: "مدیر شبکه های اجتماعی",
                canAdd: true,
            },
            {
                number: 8,
                short: "SSM",
                name_en: "Sales Service Manager",
                name_fa: "مسئول خدمات فروش",
                canAdd: true,
            },
            {
                number: 9,
                short: "CPO",
                name_en: "Chief Product Officer",
                name_fa: "مدیر محصول",
                canAdd: true,
            },
        ],
    },
    AC: {
        // 1: 100,
        // 2: 244,
        // 3: 435,
        // 4: 682,
        // 5: 1000,
        // 100: 1038218
    },
};

const baseAC = 100;
const growthFactor = 1.2;

for (let level = 1; level <= 100; level++) {
    config.AC[level] = Math.floor(baseAC * Math.pow(level, growthFactor));


}

export default config;
