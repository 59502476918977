import { useState, useEffect } from "react";

const Notif = ({ msg, title, time, type }) => {
  const [dis, setDis] = useState("hidden");

  useEffect(() => {
    setDis("flex");
    let audio = new Audio(`../../../../audio/${type}.mp3`);
    audio.play();
    let timer = setTimeout(() => {
      setDis("hidden");
      audio.pause();
    }, time * 1000);

    return () => clearTimeout(timer);
  }, [time]);

  return (
    <div
      className={`${dis} glass_card_stone transition-all duration-300 animate-bounce items-center drop-shadow-xl fixed top-[80%] right-[1%] w-72  rounded-[10px] p-2 z-50  border-dotted border-2 ${
        type == "err" ? "border-red-600" : "border-green-600"
      }`}
    >
      <div
        className={`absolute h-16 w-16 ${
          type == "err" ? "bg-red-600" : "bg-green-600"
        } opacity-80 z-40 blur-xl`}
      ></div>
      <img
        src={`${process.env.PUBLIC_URL}/gif/${type}.gif`}
        className="w-16 h-16"
      />
      <div className="mr-2 w-full h-full">
        <p className="font-bold text-xl">{title}</p>
        <p className="mt-2 text-sm">{msg}</p>
      </div>
    </div>
  );
};

export default Notif;
