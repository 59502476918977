const Loading = ({ center , mt }) => {
  return (
    <div
      className={`relative flex justify-center items-center  ${
        center ? "h-screen backdrop-blur-2xl" : `mt-${mt}`
      }`}
    >
      <div className="absolute animate-spin rounded-full h-32 w-32 border-t-2  border-b-2 border-blue-500"></div>
      <img
        src={`${process.env.PUBLIC_URL}/img/logo/png.png`}
        className="rounded-full h-28 w-28"
      />
    </div>
  );
};

export default Loading;
